export interface Informacion {
    nombre: string
    valor: string | number
}

export const documentos: Informacion[] = [
    {
        nombre: "CC",
        valor: 1
    }, {
        nombre: "NIT",
        valor: 2
    }, {
        nombre: "Pasaporte",
        valor: 3
    }, {
        nombre: "Cedula de extranjeria",
        valor: 4
    }
]

export const estadoCivil: Informacion[] = [
    {
        nombre: "Soltero/a",
        valor: 1
    },
    {
        nombre: "Casado/a",
        valor: 2
    },
    {
        nombre: "Unión libre o unión de hecho",
        valor: 3
    },
    {
        nombre: "Separado/a",
        valor: 4
    },
    {
        nombre: "Divorciado/a",
        valor: 5
    },
    {
        nombre: "Viudo/a",
        valor: 6
    }
]

export const mascotas: Informacion[] = [
    {
        nombre: "Perro",
        valor: "Perro"
    }, {
        nombre: "Gato",
        valor: "Gato"
    }, {
        nombre: "Hámster",
        valor: "Hamster"
    }, {
        nombre: "Ave",
        valor: "Ave"
    }, {
        nombre: "Pez",
        valor: "Pez"
    }
]

export const roles: Informacion[] = [
    {
        nombre: "Admin",
        valor: 1
    },
    {
        nombre: "Empleado",
        valor: 2
    }
]

export const allRoles: Informacion[] = [
    {
        nombre: "Admin",
        valor: 1
    },
    {
        nombre: "Empleado",
        valor: 2
    },
    {
        nombre: "Cliente",
        valor: 3
    }
]

export const estados: Informacion[] = [
    {
        nombre: "Activo",
        valor: 1
    },
    {
        nombre: "Inactivo",
        valor: 2
    }
]

export const acciones: Informacion[] = [
    {
        nombre: "Sesion",
        valor: "Sesion"
    }, {
        nombre: "Insertar",
        valor: "Insertar"
    }, {
        nombre: "Modificar",
        valor: "Modificar"
    }, {
        nombre: "Modificacion Cliente",
        valor: "Modificacion Cliente"
    }
]

export const tiposDePago: Informacion[] = [
    {
        nombre: "A CUOTA",
        valor: 1
    }, {
        nombre: "ABONO A CAPITAL",
        valor: 2
    }
]

export enum PeriodoEnum {
    Mensual = 1,
    Bimensual = 2,
    Trimestral = 3,
    Cuatrimestral = 4,
    Semestreal = 5,
    Abierto = 6
}