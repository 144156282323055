
import * as alertify from "alertifyjs";
import { FC } from "react";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { StatisticsWidget5 } from "../../../../_metronic/partials/widgets";
import { useTercero } from "../../../shared/providers/TerceroProvider";
import { usePropiedad } from "../providers/PropiedadProvider";
import { InformacionGeneral, Movimientos, PlanDePagos, TotalVenta } from "../../../../utils/generarExcel/estadoCuenta/types";
import { GenerarExcel } from "../../../../utils/generarExcel/estadoCuenta/GenerarExcel";
import FileSaver from "file-saver"
import { InformeServicio } from "../../../../servicios/informeServicio";
import { useAuth } from "../../../modules/auth";

export const TerceroPagoEstadisticas: FC = () => {

    const { datosCliente } = useAuth();
    
    const { 
        propiedadTercero,
        propiedad,
        saldoRestante, 
        planPagos, 
        planPagosInicial, 
        pagos, 
        totalAbonos
    } = usePropiedad();

    const descargarExcel = async () => {
        if (!datosCliente) return;

        const nombre = `${datosCliente.primerNombre} ${datosCliente.otrosNombres} ${datosCliente.primerApellido} ${datosCliente.segundoApellido}`;

        let saldoInicial = propiedad.valorTotalLote;
        const pagosIniciales: PlanDePagos[] = planPagosInicial.map((cuota, i) => {
            saldoInicial -= cuota.cuota;
            return {
                no:      i,
                fecha:   cuota.fecha,
                cuota:   cuota.cuota,
                interes: 0,
                capital: cuota.cuota,
                mora:    0,
                saldo:   saldoInicial
            }
        });

        const pagosNormales: PlanDePagos[] = planPagos.map((cuota, i) => {
            return {
                no:      i + 1,
                fecha:   cuota.fecha,
                cuota:   cuota.cuota,
                interes: cuota.interes,
                capital: cuota.cuota,
                mora:    cuota.mora,
                saldo:   cuota.saldo
            };
        });

        pagosNormales.unshift({
            no: 0,
            saldo: propiedad.valorFinanciar
        });

        const movimientos: Movimientos[] = pagos.map((pago, i) => ({
            no:             i + 1,
            referenciaPago: pago.referenciaPago,
            fecha:          pago.fecha,
            pago:           pago.valor,
            capital:        pago.capital,
            interes:        pago.interes,
            abono:          pago.abono,
            mora:           pago.mora
        }));

        const informacionGeneral: InformacionGeneral = {
            unidad: propiedadTercero.descripcion,
            correo: datosCliente.correo,
            direccion: datosCliente.direccion,
            documento: datosCliente.identificacion,
            nombre: nombre,
            telefonos: `${datosCliente.contacto} - ${datosCliente.contactoWhatsapp}`
        }

        const totalMora = planPagos.reduce((p, c) => p + c.mora, 0);
        const totalRecibido = pagos.reduce((p, c) => p + c.valor, 0);
        const abonosCapital = pagos.reduce((p, c) => p + c.abono + c.capital, 0);
        const saldoFinal = propiedad.valorTotalLote - abonosCapital;

        const totalVenta: TotalVenta = {
            valorTotal: propiedad.valorTotalLote,
            valorFinanciar: propiedad.valorFinanciar,
            totalRecibido: totalRecibido,
            cuotasMora: totalMora,
            saldoFinal: saldoFinal,
        }

        const buffer = await GenerarExcel(pagosIniciales, pagosNormales, movimientos, informacionGeneral, totalVenta);
        if (buffer) {
            FileSaver.saveAs(new Blob([buffer]), `${nombre}.xlsx`);
        } else {
            alertify.error("No se pudo generar el excel.");
        }
    }

    const descargarPdf = async () => {
        await InformeServicio.Pdf(datosCliente?.id, propiedadTercero.id);
    }

    return (
        <div className='row'>

            <div className="col-6 mb-7">
                <button type="button" className="btn btn-light-info w-100" onClick={descargarPdf}>
                    Descargar pdf
                    <img style={{ maxWidth: "40px" }} src={toAbsoluteUrl("/media/svg/files/pdf.svg")} alt='' />
                </button>
            </div>

            <div className="col-6 mb-7">
                <button type="button" className="btn btn-light-info w-100" onClick={descargarExcel}>
                    Descargar excel
                    <img style={{ maxWidth: "40px" }} src={toAbsoluteUrl("/media/svg/files/excel.svg")} alt='' />
                </button>
            </div>

            <div className='col-6 mb-7'>
                <StatisticsWidget5
                    svgIcon='briefcase'
                    color='primary'
                    iconColor='white'
                    title={saldoRestante.formatoMoneda2(true)}
                    titleColor='white'
                    description={`Saldo restante`}
                    //description={`Saldo del ${(100 - propiedad.porcentajePagoInicial).roundTo(2)}%`}
                    descriptionColor='white'
                />
            </div>

            <div className='col-6 mb-7'>
                <StatisticsWidget5
                    svgIcon='cheque'
                    color='dark'
                    iconColor='white'
                    title={(propiedad.valorTotalLote).formatoMoneda2(true)}
                    titleColor='white'
                    description={`Valor Total`}
                    //description={`Valor inicial ${propiedad.porcentajePagoInicial.roundTo(2)}%`}
                    descriptionColor='white'
                />
            </div>

            <div className='col-6 mb-7'>
                <StatisticsWidget5
                    svgIcon='chart-simple'
                    color='white'
                    iconColor='primary'
                    title={propiedad.valorFinanciar.formatoMoneda2(true)}
                    description='Valor a financiar'
                />
            </div>

            <div className='col-6'>
                <StatisticsWidget5
                    svgIcon='chart-pie-simple'
                    color='info'
                    iconColor='white'
                    title={totalAbonos.formatoMoneda2(true)}
                    titleColor='white'
                    description='Total abonos'
                    descriptionColor='white'
                />
            </div>

        </div>
    )
}