import { IPago } from "../interfaces/pago/IPago";
import { IPagoPost } from "../interfaces/pago/IPagoPost";
import { axios } from "./axiosInstance";

export class PagoServicio {

    public static CrearPago = (pago: IPagoPost) : Promise<void> => {
        return new Promise(async(res, rej)=>{
            try{
                await axios.post('/pago', pago);
                res();
            }catch(err){
                rej(err);
            }
        });
    }

    public static obtenerPagos = (TerceroId: string) : Promise<IPago[]> => {
        return new Promise(async(res, rej)=>{
            try{
                const pagos = await axios.get<IPago[]>(`/pago/lista?TerceroId=${TerceroId}`)
                pagos.sort((a, b) => new Date(b.fecha).getTime() - new Date(a.fecha).getTime());
                res(pagos);
            }catch(err){
                res([]);
            }
        });
    }
    
    public static obtenerComprobante = (pagoId: string) : Promise<string | null> => {
        return new Promise(async(res, rej)=>{
            try{
                const comprobante = await axios.get<string>(`/pago/comprobante?PagoId=${pagoId}`);
                res(comprobante);
            }catch(err){
                res(null);
            }
        });
    }

}