import { FC } from "react";
import { ListaPagosHeader } from "./components/ListaPagosHeader";
import { ListaPagos } from "./components/ListaPagos";
import { KTCard } from "../../../_metronic/helpers";
import { ListViewProvider } from "../../shared/providers/ListView/ListView";
import { useTercero } from "../../shared/providers/TerceroProvider";
import { ModalValoresProvider } from "./providers/ModalValores";

export const TerceroInformacionPagina: FC = () => {

    const { pagos } = useTercero();

    return (
        <KTCard>
            <ModalValoresProvider>
                <ListViewProvider initialData={pagos}>
                    <ListaPagosHeader />
                    <ListaPagos />
                </ListViewProvider>
            </ModalValoresProvider>
        </KTCard>
    )
}