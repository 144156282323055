/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {useLayout, usePageData} from '../../core'
import {Header} from './Header'
import {Navbar} from './Navbar'
import './Header.css'

export function HeaderWrapper() {
  const {config} = useLayout()
  if (!config.app?.header?.display) {
    return null
  }

  return (
    <header className='bg-primary'>
      <div id={'content-header'}>
        <div className='d-flex align-items-center' title='GFH GRUPO FULLHOUSE'>
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-5'>
            <Link to='/pagos' className=''>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/dinamicos/logo-sogamoso-2.png')}
                className='h-50px'
              />
            </Link>
          </div>
        </div>

        <div className='d-flex align-items-stretch justify-content-between '>
          <Navbar />
        </div>
      </div>
    </header>
  )
}
