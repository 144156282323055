/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect } from 'react'
import { IPagoInicialColumna, usePropiedad } from '../providers/PropiedadProvider'
import date from "date-and-time";
import { EstadoPagoEnum } from '../../../../interfaces/cuotas/EnumEstadoPago';

type Props = {
    className?: string
}

export const TerceroPagoInicialLista: React.FC<Props> = ({
    className = ""
}) => {

    const { propiedadTercero, planPagosInicial } = usePropiedad();

    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5 d-flex'>
                <h3 className='card-title flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Valor inicial</span>
                </h3>
                {
                    /*
                        <button
                            type='button'
                            className='btn btn-sm btn-light-primary ms-4'
                            style={{ maxHeight: "40px" }}
                            onClick={() => navigate(`/clientes/profile/pagos/?idCliente=${tercero?.id}&propiedadTerceroId=${propiedadTercero.id}&tipoPagoId=3`)}
                        >
                            <i className="fs-2 bi bi-cash-coin"></i>
                            Agregar Pago
                        </button>
                    */
                }
            </div>
            {/* end::Header */}

            {/* begin::Body */}
            <div className='card-body py-3'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                        {/* begin::Table head */}
                        <thead>
                            <tr className='fw-bold text-muted'>
                                <th style={{ width: "10px" }}></th>
                                <th style={{ width: "10px" }}>No</th>
                                <th className='min-w-100px' style={{ textAlign: "center" }}>Fecha</th>
                                <th className='min-w-100px'>Cuota Mensual</th>
                                <th className='min-w-100px'>Mora</th>
                                <th className='min-w-100px'>Cuota a pagar</th>
                                <th className='min-w-80px'>Saldo</th>
                                <th className='min-w-20px'>Estado</th>
                            </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody style={{ fontSize: "12px" }}>
                            <tr>
                                <td></td>
                                <td>0</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                    <span className='text-dark fw-bold text-hover-primary' style={{ cursor: "default" }}>
                                        {propiedadTercero.valorTotal.formatoMoneda2(true)}
                                    </span>
                                </td>
                                <td>
                                </td>
                            </tr >
                            {
                                planPagosInicial.map((pago, i) => <TerceroPagoRow key={i} pago={pago} />)
                            }
                        </tbody>
                        {/* end::Table body */}
                    </table>
                    {/* end::Table */}
                </div>
                {/* end::Table container */}
            </div>
            {/* begin::Body */}
        </div>
    )
}

interface TerceroPagoInicialRowProp {
    pago: IPagoInicialColumna
}

const TerceroPagoRow: FC<TerceroPagoInicialRowProp> = ({
    pago
}) => {

    return (
        <tr className={pago.className ?? ""}>
            <td></td>
            <td>
                <span className='text-dark fw-bold text-hover-primary' style={{ cursor: "default" }}>
                    {pago.numeroPago ?? ""}
                </span>
            </td>
            <td style={{ textAlign: "center" }}>
                <span className='text-dark fw-bold text-hover-primary' style={{ cursor: "default" }}>
                    {pago.fecha ? date.format(pago.fecha, "DD/MM/YYYY") : ""}
                </span>
            </td>
            <td>
                <span className='text-dark fw-bold text-hover-primary d-block' style={{ cursor: "default" }}>
                    {pago.cuota.formatoMoneda2(true)}
                </span>
            </td>
            <td>
                <span className={`${(pago.mora) > 0 ? "text-danger" : "text-dark"} fw-bold text-hover-primary`} style={{ cursor: "default" }}>
                    {pago.mora.formatoMoneda2(true)}
                </span>
            </td>
            <td>
                <span className={`${(pago.mora > 0 && pago.estadoPagoId != EstadoPagoEnum.pagado) ? "text-danger" : "text-dark"} fw-bold text-hover-primary`} style={{ cursor: "default" }}>
                    {pago.mora.formatoMoneda2(true)}
                </span>
            </td>
            <td>
                <span className='text-dark fw-bold text-hover-primary d-block' style={{ cursor: "default" }}>
                    {pago.saldo.formatoMoneda2(true)}
                </span>
            </td>
            <td>
                {
                    (pago.estadoPagoId == EstadoPagoEnum.pagado) ?
                        <i style={{ fontSize: "12px" }} className="bi bi-patch-check-fill text-success"><span style={{ fontSize: "12px", fontStyle: "initial" }}> Pagado</span></i>
                        : (pago.estadoPagoId == EstadoPagoEnum.proximo) ?
                            <i style={{ fontSize: "12px" }} className="bi bi-patch-exclamation-fill text-info"><span style={{ fontSize: "12px", fontStyle: "initial" }}> Proximo</span></i>
                            : ""
                }
            </td>
        </tr>
    )
}
