import {useEffect, useState} from 'react'
import {useFormulario} from '../providers/FormularioProvider'
import {useAuth} from '../../../modules/auth'

export const BotonGuardar = () => {
  const {formik} = useFormulario()
  const {loading, datosCliente} = useAuth()

  const style: React.CSSProperties = {
    width: 'auto',
    position: 'fixed',
    bottom: '7px',
    right: '7px',
    zIndex: 100,
  }
  return (
    <button
      type='submit'
      className='btn btn-primary btn-guardar'
      style={style}
      disabled={!formik.isValid || loading.SavingDatosCliente || !datosCliente}
    >
      {!loading.SavingDatosCliente && <span className='indicator-label'>Actualizar</span>}
      {loading.SavingDatosCliente && (
        <span className='indicator-progress' style={{display: 'block'}}>
          Guardando...
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        </span>
      )}
    </button>
  )
}
