export function trunc(x: number, posiciones = 0) {
  var s = x.toString()
  var l = s.length
  var decimalLength = s.indexOf('.') + 1

  if (l - decimalLength <= posiciones) {
    return x
  }
  var isNeg = x < 0
  var decimal = x % 1
  var entera = isNeg ? Math.ceil(x) : Math.floor(x)
  var decimalFormated = Math.floor(Math.abs(decimal) * Math.pow(10, posiciones))
  var finalNum = entera + (decimalFormated / Math.pow(10, posiciones)) * (isNeg ? -1 : 1)

  return finalNum
}
