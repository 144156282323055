/* eslint-disable react-hooks/exhaustive-deps */
import {KTCard, KTSVG} from '../../../_metronic/helpers'
import {useAuth} from '../../modules/auth'
import {documentos, estadoCivil} from '../../../interfaces/informacion'

export const MiPerfil = () => {
  const {datosCliente, loading} = useAuth()
  return (
    <div className={'my-10'}>
      <KTCard border utilityP={5} className='bg-light-secondary'>
        <div className='symbol symbol-100px me-2'>
          <div className='mb-10 bg-light-primary p-5 rounded d-flex justify-content-start'>
            <KTSVG
              path='/media/icons/duotune/files/fil003.svg'
              className='svg-icon svg-icon-2x svg-icon-dark me-5'
            />
            <div className='text-primary'>
              <strong>
                <span className='fs-20'>Información personal</span>
              </strong>
            </div>
          </div>
          <div className='d-flex flex-row'>
            <div className='d-flex flex-root' style={{alignItems: 'start'}}>
              {/* Tipo documento */}
              <KTSVG
                path='/media/icons/duotune/communication/com005.svg'
                className='svg-icon svg-icon-2x svg-icon-dark me-5'
              />
              <div className='fv-row'>
                <label className='form-label fs-6 fw-bolder text-dark'>Tipo documento</label>
                <div className='text-dark'>
                  {loading.datosCliente ? (
                    <div data-kt-indicator='on'>
                      <span className='indicator-progress'>
                        <span className='spinner-border spinner-border-sm align-middle'></span>
                      </span>
                    </div>
                  ) : (
                    documentos[(datosCliente ? datosCliente.tipoDocumentoId : 1) - 1].nombre
                  )}
                </div>
              </div>
            </div>

            <div className='d-flex flex-root' style={{alignItems: 'start'}}>
              {/*Numero identificación */}
              <KTSVG
                path='/media/icons/duotune/finance/fin002.svg'
                className='svg-icon svg-icon-2x svg-icon-dark me-5'
              />
              <div className='fv-row'>
                <label className='form-label fs-6 fw-bolder text-dark'>N° identificación</label>
                <div className='text-dark'>
                  {loading.datosCliente ? (
                    <div data-kt-indicator='on'>
                      <span className='indicator-progress'>
                        <span className='spinner-border spinner-border-sm align-middle'></span>
                      </span>
                    </div>
                  ) : (
                    datosCliente?.identificacion
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='separator my-10'></div>
          <div className='d-flex flex-row mb-8'>
            <div className='d-flex flex-root' style={{alignItems: 'start'}}>
              {/*Primer nombre */}
              <KTSVG
                path='/media/icons/duotune/communication/com006.svg'
                className='svg-icon svg-icon-2x svg-icon-dark me-5'
              />
              <div className='fv-row'>
                <label className='form-label fs-6 fw-bolder text-dark'>Primer nombre</label>
                <div className='text-dark'>
                  {loading.datosCliente ? (
                    <div data-kt-indicator='on'>
                      <span className='indicator-progress'>
                        <span className='spinner-border spinner-border-sm align-middle'></span>
                      </span>
                    </div>
                  ) : (
                    datosCliente?.primerNombre
                  )}
                </div>
              </div>
            </div>
            <div className='d-flex flex-root' style={{alignItems: 'start'}}>
              {/*Otros nombres */}
              <KTSVG
                path='/media/icons/duotune/communication/com006.svg'
                className='svg-icon svg-icon-2x svg-icon-dark me-5'
              />
              <div className='fv-row'>
                <label className='form-label fs-6 fw-bolder text-dark'>Otros nombres</label>
                <div className='text-dark'>
                  {loading.datosCliente ? (
                    <div data-kt-indicator='on'>
                      <span className='indicator-progress'>
                        <span className='spinner-border spinner-border-sm align-middle'></span>
                      </span>
                    </div>
                  ) : (
                    datosCliente?.otrosNombres
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex flex-row mb-8'>
            <div className='d-flex flex-root' style={{alignItems: 'start'}}>
              {/* Primer apellido */}
              <KTSVG
                path='/media/icons/duotune/communication/com006.svg'
                className='svg-icon svg-icon-2x svg-icon-dark me-5'
              />
              <div className='fv-row '>
                <label className='form-label fs-6 fw-bolder text-dark'>Primer apellido</label>
                <div className='text-dark'>
                  {loading.datosCliente ? (
                    <div data-kt-indicator='on'>
                      <span className='indicator-progress'>
                        <span className='spinner-border spinner-border-sm align-middle'></span>
                      </span>
                    </div>
                  ) : (
                    datosCliente?.primerApellido
                  )}
                </div>
              </div>
            </div>
            <div className='d-flex flex-root' style={{alignItems: 'start'}}>
              {/* Segundo apellido */}
              <KTSVG
                path='/media/icons/duotune/communication/com006.svg'
                className='svg-icon svg-icon-2x svg-icon-dark me-5'
              />
              <div className='fv-row '>
                <label className='form-label fs-6 fw-bolder text-dark'>Segundo apellido</label>
                <div className='text-dark'>
                  {loading.datosCliente ? (
                    <div data-kt-indicator='on'>
                      <span className='indicator-progress'>
                        <span className='spinner-border spinner-border-sm align-middle'></span>
                      </span>
                    </div>
                  ) : (
                    datosCliente?.segundoApellido
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Nombre comercial */}
          <div className='d-flex flex-root' style={{alignItems: 'start'}}>
            <KTSVG
              path='/media/icons/duotune/ecommerce/ecm004.svg'
              className='svg-icon svg-icon-2x svg-icon-dark me-5'
            />
            <div className='fv-row'>
              <label className='form-label fs-6 fw-bolder text-dark'>Nombre comercial</label>
              <div className='text-dark'>
                {loading.datosCliente ? (
                  <div data-kt-indicator='on'>
                    <span className='indicator-progress'>
                      <span className='spinner-border spinner-border-sm align-middle'></span>
                    </span>
                  </div>
                ) : datosCliente ? (
                  datosCliente.nombreComercial.length ? (
                    datosCliente.nombreComercial
                  ) : (
                    '---'
                  )
                ) : (
                  '---'
                )}
              </div>
            </div>
          </div>
          <div className='separator border-2 my-10'></div>
          <div className='d-flex flex-row mb-8'>
            <div className='d-flex flex-root' style={{alignItems: 'start'}}>
              {/* Profesion */}
              <KTSVG
                path='/media/icons/duotune/finance/fin006.svg'
                className='svg-icon svg-icon-2x svg-icon-dark me-5'
              />
              <div className='fv-row '>
                <label className='form-label fs-6 fw-bolder text-dark'>Profesión</label>
                <div className='text-dark'>
                  {loading.datosCliente ? (
                    <div data-kt-indicator='on'>
                      <span className='indicator-progress'>
                        <span className='spinner-border spinner-border-sm align-middle'></span>
                      </span>
                    </div>
                  ) : (
                    datosCliente?.profesion
                  )}
                </div>
              </div>
            </div>
            <div className='d-flex flex-root' style={{alignItems: 'start'}}>
              {/* Cargo */}
              <KTSVG
                path='/media/icons/duotune/ecommerce/ecm006.svg'
                className='svg-icon svg-icon-2x svg-icon-dark me-5'
              />
              <div className='fv-row mb-8'>
                <label className='form-label fs-6 fw-bolder text-dark'>Cargo</label>
                <div className='text-dark'>
                  {loading.datosCliente ? (
                    <div data-kt-indicator='on'>
                      <span className='indicator-progress'>
                        <span className='spinner-border spinner-border-sm align-middle'></span>
                      </span>
                    </div>
                  ) : (
                    datosCliente?.cargo
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex flex-root' style={{alignItems: 'start'}}>
            {/* Estado civil */}
            <KTSVG
              path='/media/icons/duotune/coding/cod002.svg'
              className='svg-icon svg-icon-2x svg-icon-dark me-5'
            />
            <div className='fv-row mb-8'>
              <label className='form-label fs-6 fw-bolder text-dark'>Estado civil</label>
              <div className='text-dark'>
                {loading.datosCliente ? (
                  <div data-kt-indicator='on'>
                    <span className='indicator-progress'>
                      <span className='spinner-border spinner-border-sm align-middle'></span>
                    </span>
                  </div>
                ) : (
                  estadoCivil[(datosCliente ? datosCliente.estadoCivilId : 1) - 1].nombre
                )}
              </div>
            </div>
          </div>
        </div>
      </KTCard>
    </div>
  )
}
