import { PropiedadTercero } from "../../../interfaces/propiedades/PropiedadesTercero";

export const propiedadTerceroIniciales: PropiedadTercero = {
    id: "",
    propiedadId: "",
    terceroId: "",
    descripcion: "",
    areaPropiedad: 0,
    interesAnual: 0,
    pagoInicial: 0,
    valorM2: 0,
    cantidadPagos: 0,
    fechaLimitePagoInicial: "",
    fechaInicioFinanciacion: "",
    periodoId: 1,
    porcentajeMora: 0,
    valorTotal: 0
}