/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import {useAuth} from '../core/Auth'
import './ValidPin.css'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {TercerosServicios} from '../../../../servicios/tercerosServicios'
import SVG from 'react-inlinesvg'
interface ValidPinProps {
  type: 'whatsapp' | 'correo'
  setType: (type: 'whatsapp' | 'correo') => void
}

export const ValidPin: React.FC<ValidPinProps> = ({type, setType}) => {
  const {
    setCurrentUser,
    saveAuth,
    setPin,
    pin,
    identificacion,
    setLoading,
    loading,
    savedsendpinwhatsappcorreo,
    saveEnviadoWhatsappCorreo,
  } = useAuth()
  const typeEnviar = type === 'whatsapp' ? 'correo' : 'whatsapp'
  const contacto = pin?.contacto ?? ''
  const [pinInputs, setPinInputs] = useState<Array<string>>(['', '', '', '', '', ''])
  const [status, setStatus] = useState('')
  const fechaObjetivo = new Date(pin?.fechaPin.toString() || '')
  const [tiempoRestante, setTiempoRestante] = useState<number>(1)
  const minutos = Math.floor(tiempoRestante / 60000)
  const segundos = Math.floor((tiempoRestante % 60000) / 1000)

  const inputRefs = useRef<Array<HTMLInputElement>>([])

  const handleKeyDown = (index, event: React.KeyboardEvent<HTMLInputElement>) => {
    if (status) {
      setStatus('')
    }
    if (
      event.key === 'Enter' &&
      tiempoRestante > 0 &&
      pinInputs.join('').length === 6 &&
      !loading.sesionCliente &&
      !loading.generarPin &&
      !loading.generarPinAlternativa
    ) {
      handleContinuar()
    }
    const isNumber = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(
      event.key.toString()
    )
    if (
      event.key !== 'Backspace' &&
      event.key !== 'Delete' &&
      event.key !== 'ArrowRight' &&
      event.key !== 'ArrowLeft' &&
      (!isNumber || pinInputs[index].length >= 1)
    ) {
      event.preventDefault()
    }
    const newPin = [...pinInputs]
    if (event.key === 'Backspace' || event.key === 'Delete' || event.key === 'ArrowLeft') {
      if (pinInputs[index].length && event.key !== 'ArrowLeft') newPin[index] = ''
      if (index > 0) {
        inputRefs[index - 1].focus()
      } else if (event.key === 'ArrowLeft') {
        inputRefs[5].focus()
      }
    }
    if (event.key === 'ArrowRight' || isNumber) {
      if (isNumber) {
        newPin[index] = event.key.toString()
      }
      if (index < 5) {
        inputRefs[index + 1].focus()
      } else if (event.key === 'ArrowRight') {
        inputRefs[0].focus()
      }
    }
    setPinInputs(newPin)
  }
  const handlePaste = (data: DataTransfer) => {
    const clipboardData = data.getData('text')
    const numericPattern = /^\d+$/
    if (clipboardData.length === 6 && numericPattern.test(clipboardData)) {
      setPinInputs(clipboardData.split(''))
    }
  }
  //para empezar el contador
  useEffect(() => {
    if (inputRefs[0]) {
      inputRefs[0].focus()
    }
    const interval = setInterval(() => {
      const fechaActual = new Date()
      const tiempoDiferencia = fechaObjetivo.getTime() - fechaActual.getTime()

      if (tiempoDiferencia > 0) {
        setTiempoRestante(tiempoDiferencia)
      } else {
        setTiempoRestante(0)
        clearInterval(interval)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [pin])
  //cuando se utliza el servico de pegado o copyPasteKeyboard globalmente
  //o dar foco cuando no lo tiene
  useEffect(() => {
    // const handleGlobalPaste = (event: ClipboardEvent) => {
    //   if (event.clipboardData) {
    //     handlePaste(event.clipboardData)
    //   }
    // }
    const handleGlobalKeyDown = (evt: KeyboardEvent) => {
      if (
        inputRefs[0] !== document.activeElement &&
        inputRefs[1] !== document.activeElement &&
        inputRefs[2] !== document.activeElement &&
        inputRefs[3] !== document.activeElement &&
        inputRefs[4] !== document.activeElement &&
        inputRefs[5] !== document.activeElement
      ) {
        const key = evt.key.toString()
        const isNumber = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(key)
        setPinInputs((prevPinInputs) => {
          const pinesText = prevPinInputs.join('')
          const newPin = [...prevPinInputs]
          const index = pinesText.length === 6 || pinesText.length === 0 ? 0 : pinesText.length
          if (isNumber) {
            inputRefs[index === 5 ? 5 : index + 1].focus()
            newPin[index] = key
          } else {
            if (key === 'Backspace') {
              newPin[pinesText.length ? pinesText.length - 1 : 0] = ''
              inputRefs[
                pinesText.length === 6 ? 4 : pinesText.length < 3 ? 0 : pinesText.length - 2
              ].focus()
            } else {
              inputRefs[index].focus()
            }
          }
          return newPin
        })
      }
    }
    // window.addEventListener('paste', (e: Event) => handleGlobalPaste(e as ClipboardEvent))
    window.addEventListener('keydown', (e: Event) => handleGlobalKeyDown(e as KeyboardEvent))
    return () => {
      // window.removeEventListener('paste', (e: Event) => handleGlobalPaste(e as ClipboardEvent))
      window.removeEventListener('keydown', (e: Event) => handleGlobalKeyDown(e as KeyboardEvent))
    }
  }, [])
  const handleContinuar = async () => {
    if (tiempoRestante <= 0) {
      setLoading({...loading, ...{generarPin: 2}})
      let promise
      if (type === 'whatsapp') {
        promise = TercerosServicios.generarPinWhatsapp(identificacion)
      } else {
        promise = TercerosServicios.generarPinCorreo(identificacion)
      }

      promise.then((data) => {
        if (data) {
          setTiempoRestante(1)
          setPin(data)
          if (savedsendpinwhatsappcorreo) {
            saveEnviadoWhatsappCorreo({
              ...savedsendpinwhatsappcorreo,
              ...{[type]: {dateExpired: new Date(data.fechaPin)}},
            })
          } else {
            saveEnviadoWhatsappCorreo({[type]: {dateExpired: new Date(data.fechaPin)}})
          }
        } else {
          setStatus('Error de servidor')
        }
        delete loading.generarPin
        setLoading({...loading})
      })
      return
    } else {
      setLoading({...loading, ...{sesionCliente: 2}})
      TercerosServicios.sesionCliente(identificacion, pinInputs.join('')).then((res) => {
        if (res) {
          saveAuth({api_token: res.token})
          setCurrentUser({...res})
          saveEnviadoWhatsappCorreo(null)
        } else {
          setStatus('Pin incorrecto')
        }
        delete loading.sesionCliente
        setLoading({...loading})
      })
    }
  }
  const handleEnviarWhatsappCorreo = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    evt.preventDefault()
    let promise
    if (typeEnviar === 'whatsapp') {
      promise = TercerosServicios.generarPinWhatsapp(identificacion)
    } else {
      promise = TercerosServicios.generarPinCorreo(identificacion)
    }
    if (promise) {
      setLoading({...loading, ...{generarPinAlternativa: 2}})
      promise.then((data) => {
        if (data) {
          setTiempoRestante(1)
          setPin({...data})
          if (savedsendpinwhatsappcorreo) {
            saveEnviadoWhatsappCorreo({
              ...savedsendpinwhatsappcorreo,
              ...{[typeEnviar]: {dateExpired: new Date(data.fechaPin)}},
            })
          } else {
            saveEnviadoWhatsappCorreo({[typeEnviar]: {dateExpired: new Date(data.fechaPin)}})
          }
          setType(typeEnviar)
        } else {
          setStatus('Error de servidor')
        }
        delete loading.generarPinAlternativa
        setLoading({...loading})
      })
    }
    // saveEnviadoWhatsappCorreo(null)
  }
  return (
    <div className='d-flex w-100 flex-column flex-lg-row-fluid'>
      <div className='mb-10'>
        <Link
          to='/auth'
          className='btn bg-light-primary  btn-text-primary bg-hover-primary text-hover-white'
          onClick={() => setPin(null)}
        >
          <SVG
            src={toAbsoluteUrl('/media/icons/duotune/arrows/arr002.svg')}
            width={24}
            height={24}
            title='back'
          />
        </Link>
      </div>
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Validación de pin</h1>
        {/* end::Title */}

        {/* begin::Link */}
        {
          <div className='text-gray-500 fw-semibold fs-6'>
            Enviado al {type === 'whatsapp' ? 'WhatsApp' : 'correo electrónico'} {contacto}
          </div>
        }
        <p>
          Tiempo restante: {minutos}:{segundos.toString().slice(0, 2).padStart(2, '0')}
        </p>
        {/* end::Link */}
      </div>
      <div className='d-flex justify-content-center mb-10'>
        <button
          onClick={(evt) => handleEnviarWhatsappCorreo(evt)}
          className={`btn ${typeEnviar === 'whatsapp' ? 'btn-success' : 'btn-info'}`}
          disabled={
            loading.sesionCliente ||
            loading.generarPin ||
            loading.generarPinAlternativa ||
            ((): number => {
              if (savedsendpinwhatsappcorreo) {
                const obj = savedsendpinwhatsappcorreo[typeEnviar]
                if (obj?.dateExpired) {
                  return obj.dateExpired.getTime() - new Date().getTime()
                }
              }
              return 0
            })() > 0
          }
        >
          {!loading.generarPinAlternativa && (
            <div className='d-flex align-items-center'>
              <div className='d-flex w-30px me-2'>
                <SVG
                  src={toAbsoluteUrl('/media/icons/duotune/communication/com011.svg')}
                  width={'100%'}
                  height={'100%'}
                  className='text-primary'
                  title='iconoPerfil'
                />
              </div>
              <span className='indicator-label'>
                Enviar por {typeEnviar === 'correo' ? 'correo electrónico' : 'WhatsApp'}
                <br></br>
                {((): string => {
                  if (savedsendpinwhatsappcorreo) {
                    const obj = savedsendpinwhatsappcorreo[typeEnviar]
                    if (obj?.dateExpired) {
                      const tiempoRestante = obj.dateExpired.getTime() - new Date().getTime()
                      if (tiempoRestante > 0) {
                        return `Intentar en: ${Math.floor(tiempoRestante / 60000)}:${Math.floor(
                          (tiempoRestante % 60000) / 1000
                        )
                          .toString()
                          .slice(0, 2)
                          .padStart(2, '0')}`
                      }
                    }
                  }
                  return ''
                })()}
              </span>
            </div>
          )}
          {loading.generarPinAlternativa && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Por favor espera...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>

      <div className='separator border-2'></div>
      <div className='codigo-input-container'>
        <h2>Ingrese tu pin de 6 dígitos</h2>
        <div className='codigo-inputs'>
          {pinInputs.map((digit, index) => (
            <input
              key={index}
              type='text'
              maxLength={1}
              ref={(ref) => (inputRefs[index] = ref)}
              onChange={() => {}}
              onKeyDown={(e) => handleKeyDown(index, e)}
              onPaste={(e) => {
                e.preventDefault()
                handlePaste(e.clipboardData)
              }}
              className='codigo-input bg-white text-danger fw-bold'
              value={digit}
            />
          ))}
        </div>
      </div>
      {!!status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{status}</div>
        </div>
      )}
      <div className='d-grid mb-10'>
        <button
          onClick={handleContinuar}
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={
            tiempoRestante > 0
              ? !(pinInputs.join('').length === 6) ||
                loading.sesionCliente ||
                loading.generarPin ||
                loading.generarPinAlternativa
              : loading.sesionCliente || loading.generarPin || loading.generarPinAlternativa
          }
        >
          {!loading.sesionCliente && !loading.generarPin && (
            <span className='indicator-label'>
              {tiempoRestante > 0 ? 'Continuar' : 'Reenviar código'}
            </span>
          )}
          {(loading.sesionCliente || loading.generarPin) && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Por favor espera...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </div>
  )
}
