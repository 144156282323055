
import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import _axios from 'axios'
import * as alertify from 'alertifyjs';
import { getAuth, removeAuth } from "../app/modules/auth";

interface Respuesta<T>{
  value: T
  errors: RespuestaError[]
  success: boolean
}

interface RespuestaError {
  errorCode: string | undefined
  message: string | undefined
}


const mostarErrores = (errors?: RespuestaError[]) => {
  if (errors) {
    if (Array.isArray(errors)) {
      for (const err of errors) {
        if (err.errorCode === "1a090d59-f850-46af-9cd8-67a14dc16ed4") {
          alertify.error("Sesion vencida");
          removeAuth();
          setTimeout(()=>document.location.href = "/#/auth", 2000);
        }else if(err.errorCode === "b1dcdca0-6816-49b3-b426-bf7682c669db") {
          alertify.error("Usuario no autorizado");
          setTimeout(()=>document.location.href = "/#/dashboard", 2000);
        }else if(err.message == "No se encontro ninguna propiedad.") continue;
        if (err.message) {
          alertify.error(err.message);
        }
      }
    }else if(typeof errors === 'object'){
      Object.values(errors).forEach((errs:any)=>{
        try{
          for (const err of errs) {
            alertify.error(err);
          }
        }catch(err){ }
      })
    }
  }
}

const onRequest = (config: InternalAxiosRequestConfig<any>) => {
  const auth = getAuth();
  if (auth && auth.api_token) {
    config.headers.Authorization = `Bearer ${auth.api_token}`
  }
  return config;
}

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  console.error(`[request error] [${JSON.stringify(error)}]`);
  return Promise.reject(error);
}

const onResponse = (response: AxiosResponse<Respuesta<any>>): AxiosResponse => {
  var res = response.data;

  if (res.success) { return res.value; }
  else {
    mostarErrores(res.errors);
    throw response;
  }
}

const onResponseError = (error: AxiosError<Respuesta<any>>): Promise<AxiosError> => {
  if (error.response) {
    mostarErrores(error.response.data.errors);
    //$('#loader').addClass('ds-none');
  }
  return Promise.reject(error.response?.data);
}

function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}

const axiosInstance = _axios.create({
  baseURL: `${ window.origin.includes("localhost") ? process.env.REACT_APP_API_BASE_URL_PRUEBAS : process.env.REACT_APP_API_BASE_URL}/api/`,
  timeout: 5000,
});

interface AxiosInstanceSetup {
  request<T = any>(config: AxiosRequestConfig): Promise<T>;
  get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
  delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
  head<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
  post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
  put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
  patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
}

export const axios: AxiosInstanceSetup = setupInterceptorsTo(axiosInstance)