/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useAuth} from './core/Auth'
import clsx from 'clsx'

const AuthLayout = () => {
  const {pin} = useAuth()
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])
  return (
    <div className='d-flex bg-light-secondary flex-column flex-lg-row flex-column-fluid h-100'>
      {/* begin::Body */}
      <div className='d-flex flex-center flex-column flex-lg-row-fluid w-lg-50 p-5 pt-10 order-2 order-lg-1'>
        {/* begin::Form */}
        <div className='d-flex flex-center w-100'>
          {/* begin::Wrapper */}
          <div className='d-flex flex-center w-75'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}

        {/* begin::Footer */}
        {/* end::Footer */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div
        className={clsx({
          'd-none d-lg-flex w-50 bgi-size-cover bgi-position-center order-1 order-lg-2': pin,
          'd-flex d-lg-flex w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2': !pin,
        })}
        style={{backgroundImage: `url(${toAbsoluteUrl('/media/dinamicos/login-bg.png')})`}}
      >
        {/* begin::Content */}
        <div className='d-flex flex-column flex-center py-5 px-5  w-100'>
          {/* begin::Logo */}
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/dinamicos/main-logo.png')}
            className='h-200px h-lg-250px'
          />
          {/* end::Logo */}

          {/* begin::Image */}

          <img
            className={'d-none d-lg-flex mx-auto w-100 mb-5 mb-lg-20'}
            src={toAbsoluteUrl('/media/dinamicos/auth-screens.jpg')}
            alt=''
          />

          {/* end::Image */}

          {/* begin::Title */}
          <h1 className='text-white fs-2qx fw-bolder text-center'>Sistema de gestión interna</h1>
          {/* end::Title */}

          {/* begin::Text */}
          {/* <div className='text-white fs-base text-center'>
            Optimiza la{' '}
            <a  className='opacity-75-hover text-warning fw-bold me-1'>
              eficiencia{' '}
            </a>
            con el Sistema de Gestión Interna de{' '}
            <a  className='opacity-75-hover text-warning fw-bold me-1'>
              Grupo FullHouse.
            </a>
          </div> */}
          {/* end::Text */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::Aside */}
    </div>
  )
}

export {AuthLayout}
