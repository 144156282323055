import {FC} from 'react'
import {useFormulario} from '../providers/FormularioProvider'
import {FormularioCampo} from './FormularioLayout'
import {FormularioInput} from '../../../../_metronic/partials/formulario/formulario'

export const TerceroDatosContacto: FC = () => {
  const {formik} = useFormulario()

  return (
    <div className='card mb-5 mb-xl-10 bg-light-secondary'>
      <div className='card-header border-0'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Contacto</h3>
        </div>
      </div>
      <div id='kt_crear_tercero_contacto' className='collapse show'>
        <div className='card-body border-top p-9 row row-cols-2'>
          <FormularioCampo nombre='Alias'>
            <div className='row p-0'>
              <FormularioInput
                placeholder='Alias'
                props={formik.getFieldProps('alias')}
                validProp={formik.touched.alias}
                error={formik.errors.alias}
              />
            </div>
          </FormularioCampo>

          <FormularioCampo nombre='Celular'>
            <div className='row p-0'>
              <FormularioInput
                requerido
                placeholder='Celular'
                props={formik.getFieldProps('contacto')}
                validProp={formik.touched.contacto}
                error={formik.errors.contacto}
              />
            </div>
          </FormularioCampo>
          <FormularioCampo nombre='Contacto WhatsApp'>
            <div className='row p-0'>
              <FormularioInput
                requerido
                placeholder='Contacto WhatsApp'
                props={formik.getFieldProps('contactoWhatsapp')}
                validProp={formik.touched.contactoWhatsapp}
                error={formik.errors.contactoWhatsapp}
              />
            </div>
          </FormularioCampo>

          <FormularioCampo nombre='Correo'>
            <div className='row p-0'>
              <FormularioInput
                requerido
                placeholder='Correo'
                props={formik.getFieldProps('correo')}
                validProp={formik.touched.correo}
                error={formik.errors.correo}
              />
            </div>
          </FormularioCampo>
        </div>
      </div>
    </div>
  )
}
