import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, SendPinWhatsappCorreoModel} from './_models'
import * as authHelper from './AuthHelpers'
import {WithChildren} from '../../../../_metronic/helpers'
import * as jose from 'jose'
import {DatosClienteModel, PinModel, SesionClienteModel} from '../../../../interfaces/TerceroModelo'
import {TercerosServicios} from '../../../../servicios/tercerosServicios'

type AuthContextProps = {
  auth: AuthModel | null
  saveAuth: (auth: AuthModel | null) => void
  currentUser: SesionClienteModel | null
  setCurrentUser: Dispatch<SetStateAction<SesionClienteModel | null>>
  logout: () => void
  setPin: (responsePin: PinModel | null) => void
  pin: PinModel | null
  setIdentificacion: (identificacion: string) => void
  identificacion: string
  setDatosCliente: (datosCliente: DatosClienteModel | null) => void
  datosCliente: DatosClienteModel | null
  setLoading: (state) => void
  loading: any
  savedsendpinwhatsappcorreo: SendPinWhatsappCorreoModel | null
  saveEnviadoWhatsappCorreo: (sendPinWhatsappCorreo: SendPinWhatsappCorreoModel | null) => void
}

const initAuthContextPropsState = {
  pagos: [],
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: null,
  setCurrentUser: () => {},
  logout: () => {},
  setPin: () => {},
  pin: null,
  setIdentificacion: () => {},
  identificacion: '',
  setDatosCliente: () => {},
  datosCliente: null,
  setLoading: () => {},
  loading: {},
  savedsendpinwhatsappcorreo: authHelper.getSavedSendPinWhatsappCorreo(),
  saveEnviadoWhatsappCorreo: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}
const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | null>(authHelper.getAuth())
  const [savedsendpinwhatsappcorreo, setSaveSendPinWhatsappCorreo] =
    useState<SendPinWhatsappCorreoModel | null>(authHelper.getSavedSendPinWhatsappCorreo())
  const [pin, setPin] = useState<PinModel | null>(null)
  const [identificacion, setIdentificacion] = useState<string>('')
  const [currentUser, setCurrentUser] = useState<SesionClienteModel | null>(null)
  const [datosCliente, setDatosCliente] = useState<DatosClienteModel | null>(null)
  const [loading, setLoading] = useState<any>({})

  const saveEnviadoWhatsappCorreo = (sendPinWhatsappCorreo: SendPinWhatsappCorreoModel | null) => {
    setSaveSendPinWhatsappCorreo(sendPinWhatsappCorreo)
    if (sendPinWhatsappCorreo) {
      authHelper.setSaveSendPinWhatsappCorreo(sendPinWhatsappCorreo)
    } else {
      authHelper.removeSavedSendPinWhatsappCorreo()
    }
  }
  const saveAuth = (auth: AuthModel | null) => {
    setAuth(auth)
    if (auth) {
      setPin(null)
      setIdentificacion('')
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = async () => {
    if (auth && auth.api_token) {
      await TercerosServicios.Logout().then(() => {
        saveAuth(null)
        setCurrentUser(null)
        setDatosCliente(null)
      })
    }
  }
  useEffect(() => {
    const ejet = async () => {
      if (auth && auth.api_token && !datosCliente) {
        setLoading({...loading, ...{datosCliente: 2}})
        const tercero = await TercerosServicios.ObtenerUsuario();
        if (tercero) {
          setDatosCliente(tercero)
        } else {
          logout()
        }
      }
    }
    
    ejet()
    // eslint-disable-next-line
  }, [auth])
  useEffect(() => {
    if (datosCliente) {
      delete loading.datosCliente
      setLoading({...loading})
    }
    // eslint-disable-next-line
  }, [datosCliente])

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        setDatosCliente,
        datosCliente,
        logout,
        setPin,
        pin,
        setIdentificacion,
        identificacion,
        setLoading,
        loading,
        savedsendpinwhatsappcorreo,
        saveEnviadoWhatsappCorreo,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser, saveEnviadoWhatsappCorreo} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          //const { data } = await getUserByToken(apiToken)
          const payload = jose.decodeJwt(apiToken) as unknown as SesionClienteModel
          if (payload) {
            const user: SesionClienteModel = {...payload, token: apiToken}
            setCurrentUser(user)
          }
        }
      } catch (error) {
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.api_token) {
      requestUser(auth.api_token)
    } else {
      // saveEnviadoWhatsappCorreo(null)
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
