import clsx from 'clsx'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher} from '../../../partials'
import {useLayout} from '../../core'
import SVG from 'react-inlinesvg'

const itemClass = 'ms-1 ms-md-4'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px'
const userAvatarClass = 'symbol-35px'
const btnIconClass = 'fs-2'

const Navbar = () => {
  const {config} = useLayout()
  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher
          toggleBtnClass='d-flex cursor-pointer symbol justify-content-center align-items-center h-40px w-40px bg-hover-light-dark text-hover-primary'
          toggleBtnIconClass='text-white fs-1'
        />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={
            'd-flex cursor-pointer symbol justify-content-center align-items-center h-40px w-40px bg-hover-light-dark text-white text-hover-primary'
          }
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <SVG
            src={toAbsoluteUrl('/media/icons/duotune/communication/com006.svg')}
            width={35}
            height={35}
            title='iconoPerfil'
          />
        </div>
        <HeaderUserMenu />
      </div>
    </div>
  )
}

export {Navbar}
