/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {useAuth} from '../core/Auth'
import {TercerosServicios} from '../../../../servicios/tercerosServicios'

const loginSchema = Yup.object().shape({
  identificacion: Yup.string()
    .min(3, 'Mínimo 3 símbolos')
    .max(20, 'Máximo 20 símbolos')
    .required('Es requerido'),
})

export function Login() {
  const [loading, setLoading] = useState(false)
  const {
    saveAuth,
    setPin,
    setIdentificacion,
    savedsendpinwhatsappcorreo,
    saveEnviadoWhatsappCorreo,
    identificacion,
  } = useAuth()
  const initialValues = {
    identificacion,
  }
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      TercerosServicios.generarPinWhatsapp(values.identificacion.toString()).then((data) => {
        if (data) {
          setIdentificacion(values.identificacion.toString())
          setPin(data)
          navigate('valid-pin')
          if (savedsendpinwhatsappcorreo) {
            saveEnviadoWhatsappCorreo({
              ...savedsendpinwhatsappcorreo,
              ...{whatsapp: {dateExpired: new Date(data.fechaPin)}},
            })
          } else {
            saveEnviadoWhatsappCorreo({whatsapp: {dateExpired: new Date(data.fechaPin)}})
          }
        } else {
          setPin(null)
          setIdentificacion('')
          saveAuth(null)
          setStatus('Número de identificación incorrecto')
          setLoading(false)
        }
      })
    },
  })
  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>, form) => {
    const {name, value} = evt.target
    const isNumbersOrLetters = /^[0-9a-zA-Z]*$/.test(value)
    if (isNumbersOrLetters) {
      form.setFieldValue(name, value)
    }
  }
  const handleKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    const key = evt.key
    const isNumber = /^[0-9]$/.test(key)
    const isLetter = /^[a-zA-Z]$/.test(key)
    if (key !== 'Backspace' && key !== 'Delete' && key !== 'Enter' && !isNumber && !isLetter) {
      evt.preventDefault()
    }
  }

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div className='mb-10 bg-light-primary p-8 rounded d-flex justify-content-center'>
          <div className='text-primary'>
            Usa tu <strong>identificación</strong> para continuar.
          </div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Número de identificación</label>
        <input
          placeholder='Número'
          {...formik.getFieldProps('identificacion')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.identificacion && formik.errors.identificacion},
            {
              'is-valid': formik.touched.identificacion && !formik.errors.identificacion,
            }
          )}
          type='text'
          name='identificacion'
          autoComplete='off'
          onKeyDown={(e) => handleKeyDown(e)}
          onChange={(e) => handleChange(e, formik)}
        />
        {formik.touched.identificacion && formik.errors.identificacion && (
          <div className='alert bg-light-danger d-flex flex-column flex-sm-row p-5 mb-10'>
            <div className='d-flex flex-column text-danger pe-0 pe-sm-10'>
              <span>{formik.errors.identificacion}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        {/* <Link to='/auth/forgot-password' className='link-primary'>
          Olvidaste la contraseña ?
        </Link> */}
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={!formik.isValid || loading}
        >
          {!loading && <span className='indicator-label'>Continuar</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Por favor espera...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
