import { IInformeCuenta } from "../interfaces/informes/IInformeCuenta";
import { IInformeEdades } from "../interfaces/informes/IInformeEdades";
import { PropiedadCaracteristicas } from "../interfaces/propiedades/PropiedadCaracteristicas";
import { axios } from "./axiosInstance";

const url = `${ window.origin.includes("localhost") ? process.env.REACT_APP_API_BASE_URL_PRUEBAS : process.env.REACT_APP_API_BASE_URL}/api/`;

export class InformeServicio {

    public static Pdf = (terceroid, propiedadid) : Promise<void> => {
        return new Promise(async(res, rej)=>{
            try{
                window.open(`${url}informe/pdf?propiedadTerceroId=${propiedadid}`, '_blank');
                res();
            }catch(err){
                rej(err);
            }
        });
    }

    public static Excel = () : Promise<void> => {
        return new Promise(async(res, rej)=>{
            try{
                await axios.get<PropiedadCaracteristicas[]>('/informe/excel')
                res();
            }catch(err){
                rej(err);
            }
        });
    }

    public static InformeEdades = (terceroId: string) : Promise<IInformeEdades[]> => {
        return new Promise(async(res, rej)=>{
            try{
                var _res = await axios.get<IInformeEdades[]>(`/informe/informe-edades?TerceroId=${terceroId}`);
                _res.map((c, i, a) => {
                    a[i].fecha = new Date(c.fecha);
                });
                _res.sort((a, b) => b.dias - a.dias);
                res(_res);
            }catch(err){
                rej(err);
            }
        });
    }

    public static InformeCuenta = (fechaFinal: string | null, estadoPago: number) : Promise<IInformeCuenta[]> => {
        return new Promise(async(res, rej)=>{
            try{

                var query = `EstadoPago=${estadoPago}`;

                if(fechaFinal) query += `&FechaFinal=${fechaFinal}`;

                var _res = await axios.get<IInformeCuenta[]>(`/informe/informe-cuenta?${query}`);
                _res.map((c, i, a) => {
                    a[i].fecha = new Date(c.fecha);
                });
                _res.sort((a, b) => a.fecha.getTime() - b.fecha.getTime());
                res(_res);
            }catch(err){
                rej(err);
            }
        });
    }
}