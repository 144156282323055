import clsx from "clsx"
import React, { FC, useEffect, useMemo, useState, useCallback } from "react"
import { FormularioLayouProps, FormularioInputProps, FormularioSelectProps, FormulatioRadioProps, FormularioDataListProps, FormularioInputMonetarioProps, InputNoEditableProps, FormularioInputNumericProps, FormularioInputDecimalProps } from "./formularioTypes";
import { Informacion } from "../../../app/shared/datosFormularios/Informacion";
import { BotonEliminar } from "../../../app/shared/misc/BotonEliminar";

export const FormularioCampo: FC<FormularioLayouProps> = ({
    nombre,
    requerido = false,
    children
}) => {
    return (
        <div className="col-12">
            <div className="row">
                <div className="col-12 col-sm-2 mb-0 d-flex align-items-center">
                    <label className='col-form-label fw-bold fs-6 p-0'>{nombre}{requerido ? <span className="text-danger"> *</span> : ''}</label>
                </div>
                <div className="col-12 col-sm-10 mt-0">
                    {children}
                </div>
            </div>
        </div>
    )
}

export const FormularioInput: FC<FormularioInputProps> = ({
    nombre,
    placeholder,
    type = "text",
    props,
    validProp,
    error,
    requerido = false,
    disabled = false,
    validate = true,
    autoCapitalLetter = false,
    deleteSpaces = false,
    min,
    max
}) => {

    const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        if (deleteSpaces) e.target.value = e.target.value.replace(/\s/g, '');
        if (autoCapitalLetter) e.target.value = e.target.value.split(" ").map(p => p.length > 0 ? (p[0].toUpperCase() + p.slice(1)) : "").join(" ");
        props.onChange(e);
    }

    return (
        <div className='mb-2 col p-1'>
            {nombre && <label className='form-label fs-6 fw-bolder text-dark'>{nombre} {(requerido) ? <span className="text-danger">*</span> : ""}</label>}
            <input
                min={min}
                max={max}
                type={type}
                autoComplete='off'
                placeholder={placeholder}
                name={props.name}
                onBlur={props.onBlur}
                onChange={onChange}
                value={props.value ?? ""}
                className={clsx(
                    'form-control form-control-sm bg-transparent',
                    { 'is-invalid': validProp && error },
                    {
                        'is-valid': validate && validProp && !error,
                    }
                )}
                disabled={disabled}
            />
            {validProp && error && (
                <div className='fv-plugins-message-container'>
                    <span className="text-danger" role='alert'>{error}</span>
                </div>
            )}
        </div>
    )
}

export const FormularioInputNumeric: FC<FormularioInputNumericProps> = ({
    nombre,
    placeholder,
    props,
    validProp,
    error,
    requerido = false,
    disabled = false,
    validate = true,
}) => {

    const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        //var number = e.target.value;
        var replaced = e.target.value.replace(/[^0-9.]/g, '');
        var numeroB = replaced.split('.');
        if (numeroB.length == 1) var numeroFinal = numeroB[0];
        else var numeroFinal = numeroB.reduce((p, c, i, a) => p + ((i == a.length - 1) ? "." + c : c), "");
        var valorFormateado = numeroFinal;
        e.target.value = valorFormateado;
        props.onChange(e);
    }

    return (
        <div className='mb-2 col p-1'>
            {nombre && <label className='form-label fs-6 fw-bolder text-dark'>{nombre} {(requerido) ? <span className="text-danger">*</span> : ""}</label>}
            <input
                autoComplete='off'
                placeholder={placeholder}
                name={props.name}
                onBlur={props.onBlur}
                onChange={onChange}
                value={props.value ?? ""}
                className={clsx(
                    'form-control form-control-sm bg-transparent',
                    { 'is-invalid': validProp && error },
                    {
                        'is-valid': validate && validProp && !error,
                    }
                )}
                disabled={disabled}
            />
            {validProp && error && (
                <div className='fv-plugins-message-container'>
                    <span className="text-danger" role='alert'>{error}</span>
                </div>
            )}
        </div>
    )
}

export const FormularioSelect: FC<FormularioSelectProps> = ({
    nombre,
    props,
    validProp,
    error,
    options,
    requerido = false,
    placeholder,
    defaultValue,
    setFieldValue
}) => {

    useEffect(() => {
        if (defaultValue && setFieldValue) {
            setFieldValue(props.name, defaultValue, false);
        }
    }, [defaultValue])

    return (
        <div className='fv-row mb-2 col p-1'>
            {nombre && <label className='form-label fs-6 fw-bolder text-dark'>{nombre} {(requerido) ? <span className="text-danger">*</span> : ""}</label>}
            <select
                required
                className={clsx(
                    'form-select form-select-sm bg-transparent',
                    { 'is-invalid': validProp && error },
                    {
                        'is-valid': validProp && !error,
                    }
                )}
                name={props.name}
                onBlur={props.onBlur}
                value={props.value}
                onChange={props.onChange}
            >
                <option value="" disabled hidden>{placeholder}</option>
                {
                    options.map((option, i) => (
                        <option key={i} value={option.valor}>{option.nombre}</option>
                    ))
                }
            </select>
            {validProp && error && (
                <div className='fv-plugins-message-container'>
                    <span className='text-danger'>{error}</span>
                </div>
            )}
        </div>
    )
}

export const FormularioRadio: FC<FormulatioRadioProps> = ({
    forGroup,
    nombre,
    props,
    validProp,
    error,
    options,
    requerido = false
}) => {

    return (
        <div className='fv-row mb-2 col p-1'>
            {/*<label className='form-label fs-6 fw-bolder text-dark'>{nombre} {(requerido) ? <span className="text-danger">*</span> : ""}</label>*/}
            <div className="form-check">
                {
                    options.map((option, i) => (
                        <div key={i}>
                            <input
                                name={props.name}
                                onBlur={props.onBlur}
                                onChange={props.onChange}
                                className="form-check-input"
                                type="radio"
                                value={option.valor}
                            />
                            <label className="form-check-label"> {option.nombre} </label>
                        </div>
                    ))
                }
            </div>
            {validProp && error && (
                <div className='fv-plugins-message-container'>
                    <span role='alert'>{error}</span>
                </div>
            )}
        </div>
    )
}

export const FormularioDataList: FC<FormularioDataListProps> = ({
    options,
    nombre,
    props,
    validProp,
    error,
    setFieldValue,
    placeholder,
    defaultValue,
    disabled = false,
    requerido
}) => {
    const [show, setShow] = useState(false);
    const [valor, setValor] = useState("");

    useEffect(() => {
        if (defaultValue && defaultValue != "") setValor(defaultValue)
    }, [defaultValue])

    const opcionesFiltradas = useMemo(() => {
        try {
            const valorNormalizado = valor.normalizar(true);
            const filtrado = options.filter(({ nombre, valor }) => (nombre + valor).normalizar(true).includes(valorNormalizado));
            return filtrado;
        } catch (err) {
            return []
        }
    }, [valor, options]);

    useEffect(() => {
        const valorNormalizado = valor.normalizar(true);
        const vv = opcionesFiltradas.find(e => e.nombre.normalizar(true) === valorNormalizado);
        if (vv) setFieldValue(props.name, vv.valor);
    }, [valor]);

    const handleOptionSelect = (opcion: Informacion) => {
        setFieldValue(props.name, opcion.valor);
        setValor(opcion.nombre)
    };

    const reset = () => {
        setFieldValue(props.name, "", false);
        setValor("");
    }

    const onChange: React.ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
        setValor(e.target.value);
        if (e.target.value === "") props.onChange(e);
    }, []);

    const onBlur: React.ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
        props.onBlur(e);
        setTimeout(() => setShow(false), 250);
    }, []);

    return (
        <div className="col p-1 mb-2">
            {nombre && <label className='form-label fs-6 fw-bolder text-dark'>{nombre} {(requerido) ? <span className="text-danger">*</span> : ""}</label>}
            <input
                hidden
                name={props.name}
                onChange={() => { }}
                value={props.value ?? ""}
            />
            <div className="input-group">
                <input
                    disabled={disabled}
                    autoComplete="off"
                    id="select-datalist"
                    className={clsx(
                        'form-control form-control-sm bg-transparent',
                        { 'is-invalid': validProp && error },
                        {
                            'is-valid': validProp && !error,
                        }
                    )}
                    type="text"
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={() => setShow(true)}
                    name={props.name}
                    value={valor}
                    placeholder={placeholder}
                />
                <div className="input-group-append">
                    <BotonEliminar disabled={disabled} eliminar={reset} style={{ borderRadius: "0px 5px 5px 0px" }} />
                </div>
            </div>
            <ul className="list-group options-list datalist-ul hide-scroll border border-2 rounded">
                {(show) ? opcionesFiltradas.map((option, index) => (
                    <li
                        key={index}
                        className="list-group-item pe-auto datalist-li"
                        //style={current==index ? {backgroundColor: "gainsboro"} : {}}
                        onClick={() => handleOptionSelect(option)}
                    >
                        {option.opcion}
                    </li>
                )) : ""}
            </ul>
            {validProp && error && (
                <div className='fv-plugins-message-container'>
                    <span className='text-danger'>{error}</span>
                </div>
            )}
        </div>

    );
};

export const FormularioInputDecimal: FC<FormularioInputDecimalProps> = ({
    props,
    nombre,
    placeholder,
    error,
    validProp,
    requerido,
    title,
    validate = true,
    min,
    max,
    disabled = false
}) => {

    const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        var number = e.target.value;

        var valorFormateado = number.replace(/[^0-9.]/g, '');
        var points = valorFormateado.split('.');
        if (points.length > 1) valorFormateado = points.shift() + '.' + points.join('');
        else valorFormateado = points[0];
        valorFormateado = valorFormateado.replace(/^0+(?!$)/g, '');

        if (
            max != undefined && parseFloat(valorFormateado) > max
            || min != undefined && parseFloat(valorFormateado) < min
        ) {
            valorFormateado = e.target.defaultValue;
        }

        e.target.value = valorFormateado;
        props.onChange(e);
    };

    const onBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
        var number = e.target.value;
        if (number.length > 0 && number[number.length - 1] == ".") {
            e.target.value = number.slice(0, number.length - 1);
        }
        props.onChange(e);
        props.onBlur(e);
    }

    return (
        <div className='fv-row mb-2 col p-1'>
            {nombre && <label className='form-label fs-6 fw-bolder text-dark' title={title}>{nombre} {(requerido) ? <span className="text-danger">*</span> : ""}</label>}
            <input
                disabled={disabled}
                placeholder={placeholder}
                name={props.name}
                onBlur={onBlur}
                onChange={handleInputChange}
                value={props.value ?? ""}
                className={clsx(
                    'form-control form-control-sm bg-transparent',
                    { 'is-invalid': validate && validProp && error },
                    {
                        'is-valid': validate && validProp && !error,
                    }
                )}

            />
            {validate && validProp && error && (
                <div className='fv-plugins-message-container'>
                    <span className="text-danger" role='alert'>{error}</span>
                </div>
            )}
        </div>
    );
};

export const FormularioInputEntero: FC<FormularioInputDecimalProps> = ({
    props,
    nombre,
    placeholder,
    error,
    validProp,
    requerido,
    title,
    validate = true,
    min,
    max,
    disabled = false
}) => {


    const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        var number = e.target.value;

        var valorFormateado = number.replace(/\D/g, '').replace(/^0+(?!$)/g, '');

        if (
            max != undefined && parseFloat(valorFormateado) > max
            || min != undefined && parseFloat(valorFormateado) < min
        ) {
            valorFormateado = e.target.defaultValue;
        }

        e.target.value = valorFormateado;
        props.onChange(e);
    };

    return (
        <div className='fv-row mb-2 col p-1'>
            {nombre && <label className='form-label fs-6 fw-bolder text-dark' title={title}>{nombre} {(requerido) ? <span className="text-danger">*</span> : ""}</label>}
            <input
                disabled={disabled}
                placeholder={placeholder}
                name={props.name}
                onBlur={props.onBlur}
                onChange={handleInputChange}
                value={props.value ?? ""}
                className={clsx(
                    'form-control form-control-sm bg-transparent',
                    { 'is-invalid': validate && validProp && error },
                    {
                        'is-valid': validate && validProp && !error,
                    }
                )}

            />
            {validate && validProp && error && (
                <div className='fv-plugins-message-container'>
                    <span className="text-danger" role='alert'>{error}</span>
                </div>
            )}
        </div>
    );
};

export const FormularioInputMonetario: FC<FormularioInputMonetarioProps> = ({
    props,
    nombre,
    placeholder,
    error,
    validProp,
    requerido,
    title,
    validate = true,
    setFieldValue,
    disabled = false
}) => {

    const [valor, setValor] = useState("");

    const formato = (valor: string) => {
        var numero = parseFloat(valor);
        var valorFormateado = (isNaN(numero) ? 0 : numero).formatoMoneda2(true);
        return valorFormateado;
    }

    useEffect(() => {
        if (props.value !== undefined && props.value !== null) {
            setValor(formato(props.value.toString()));
        }
    }, [props.value]);

    const onChange: React.ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
        var number = e.target.value.replace(/\D/g, '');
        if (number == "") number = "0";
        e.target.value = number;
        setFieldValue(props.name, parseFloat(number));
    }, []);

    return (
        <div className='fv-row mb-2 col p-1'>
            {nombre && <label className='form-label fs-6 fw-bolder text-dark' title={title}>{nombre} {(requerido) ? <span className="text-danger">*</span> : ""}</label>}
            <input
                hidden
                name={props.name}
                onChange={() => { }}
                value={props.value ?? ""}
            />
            <input
                disabled={disabled}
                className={clsx(
                    'form-control form-control-sm bg-transparent',
                    { 'is-invalid': validate && validProp && error },
                    {
                        'is-valid': validate && validProp && !error,
                    }
                )}
                type="text"
                onChange={onChange}
                onBlur={props.onBlur}
                name={props.name}
                value={valor == "" ? "$ 0" : valor}
                placeholder={placeholder}
            />
            {validate && validProp && error && (
                <div className='fv-plugins-message-container'>
                    <span className="text-danger" role='alert'>{error}</span>
                </div>
            )}
        </div>
    );
};

export const InputNoEditable: FC<InputNoEditableProps> = ({
    nombre,
    type,
    value,
    disabled = true,
    className = ""
}) => {
    return (
        <div className={`mb-2 p-1 ${className}`}>
            {nombre && <label className='form-label fs-6 fw-bolder text-dark'>{nombre}</label>}
            <input
                type={type}
                autoComplete='off'
                value={value ?? ""}
                disabled={disabled}
                style={{ color: "black" }}
                className="form-control form-control-sm bg-transparent"
            />
        </div>
    )
}