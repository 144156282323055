import * as Yup from 'yup'

export interface PinModel {
  fechaPin: Date
  contacto: string
}
export interface SesionClienteModel {
  id: string
  nombre: string
  identificacion: string
  rol: 'Cliente'
  rolId: number
  correo: string
  token: string
}
export interface DatosClienteModel extends DatosPersonales {
  id: string
  contactoWhatsapp: string
  nombreComercial: string
  alias: string
  hijos: TerceroHijoModelo[]
  mascotas: TerceroMascotaModelo[]
  pareja: DatosPersonales
}
export interface DatosPersonales {
  tipoDocumentoId: number
  identificacion: string
  primerNombre: string
  otrosNombres: string
  primerApellido: string
  segundoApellido: string
  profesion: string | null
  cargo: string | null
  estadoCivilId: number
  codigoActividad: number
  municipioId: number
  barrio: string
  direccion: string
  contacto: string
  correo: string
  estadoId: number
}

export interface ValuesActualizarModel {
  id: string
  municipioId: number
  barrio: string
  direccion: string
  alias: string
  contacto: string
  contactoWhatsapp: string
  correo: string
}

export interface TerceroHijoModelo {
  nombre: string
  colegio: string
  fechaNacimiento: string
  estadoId: number
}
export interface TerceroMascotaModelo {
  nombre: string
  mascota: number
  raza: string
  estadoId: number
}

const datosPersonaesSchema = {
  identificacion: Yup.string()
    .matches(/^[0-9]+$/, 'Numero de identificacion invalido.')
    .min(9, 'Min 9 digitos.')
    .max(10, 'Max 10 digitos.')
    .required('Campo requerido.'),
  primerNombre: Yup.string()
    .min(3, 'Mínimo 3 símbolos.')
    .max(50, 'Máximo 50 símbolos.')
    .required('Campo requerido.'),
  primerApellido: Yup.string()
    .min(3, 'Mínimo 3 símbolos.')
    .max(50, 'Máximo 50 símbolos.')
    .required('Campo requerido.'),
  segundoApellido: Yup.string().min(3, 'Mínimo 3 símbolos.').max(50, 'Máximo 50 símbolos.'),
  nombreComercial: Yup.string().min(3, 'Mínimo 3 símbolos.').max(50, 'Máximo 50 símbolos.'),
  otrosNombres: Yup.string().min(3, 'Mínimo 3 símbolos.').max(50, 'Máximo 50 símbolos.'),
  profesion: Yup.string().max(50, 'Máximo 50 símbolos.').required('Campo requerido.'),
  cargo: Yup.string().max(50, 'Máximo 50 símbolos.').required('Campo requerido.'),
  estadoCivil: Yup.number().required('Campo requerido.'),
}

export const terceroCrearSchema = Yup.object().shape({
  ...datosPersonaesSchema,
  municipioId: Yup.string().required('Campo requerido.'),
  barrio: Yup.string().max(50, 'Máximo 50 símbolos.'),
  direccion: Yup.string().max(50, 'Máximo 50 símbolos.').required('Campo requerido.'),
  alias: Yup.string().min(3, 'Mínimo 3 símbolos.').max(50, 'Máximo 50 símbolos.'),
  celular: Yup.string()
    .matches(/^[0-9]+$/, 'Numero celular invalido.')
    .required('Campo requerido.'),
  correo: Yup.string()
    .email('Correo invalido.')
    .min(3, 'Mínimo 3 símbolos.')
    .max(50, 'Máximo 50 símbolos.')
    .required('Campo requerido.'),
  hijos: Yup.array().of(
    Yup.object().shape({
      nombre: Yup.string().max(50, 'Máximo 50 símbolos.').required('Campo requerido.'),
      colegio: Yup.string().max(50, 'Máximo 50 símbolos.').required('Campo requerido.'),
      fechaNacimiento: Yup.date().required('Campo requerido.'),
    })
  ),
  mascotas: Yup.array().of(
    Yup.object().shape({
      nombre: Yup.string().max(50, 'Máximo 50 símbolos.').required('Campo requerido.'),
      mascota: Yup.string().max(50, 'Máximo 50 símbolos.').required('Campo requerido.'),
      raza: Yup.string().max(50, 'Máximo 50 símbolos.').required('Campo requerido.'),
    })
  ),
  pareja: Yup.object().shape(datosPersonaesSchema),
})
export const datosClienteSchema = Yup.object().shape({
  alias: Yup.string().min(3, 'Mínimo 3 símbolos.').max(50, 'Máximo 50 símbolos.'),
  contacto: Yup.string()
    .matches(/^\d{10}$/, 'Numero celular invalido.')
    .required('Campo requerido.'),
  contactoWhatsapp: Yup.string()
    .matches(/^\d{10}$/, 'Numero celular invalido.')
    .required('Campo requerido.'),
  correo: Yup.string()
    .email('Correo invalido.')
    .min(3, 'Mínimo 3 símbolos.')
    .max(50, 'Máximo 50 símbolos.')
    .required('Campo requerido.'),
  direccion: Yup.string().max(50, 'Máximo 50 símbolos.').required('Campo requerido.'),
  municipioId: Yup.string().required('Campo requerido.'),
  barrio: Yup.string().max(50, 'Máximo 50 símbolos.'),
})