/**
 * Provider para el calculo de los valores de la propiedad.
 * 
 * Toma los valores del input -> ( @see PropiedadesTercero )
 * y cuando detecte un cambio en uno de esos valores del input crea un nuevo objeto a partir de esos valores -> ( @see Propiedad )
 * 
 */

import { createContext, useState, useMemo, useEffect, useContext, useCallback } from "react"
import { useSearchParams } from "react-router-dom"
import { WithChildren } from "../../../../_metronic/helpers"
import { EstadoPagoEnum } from "../../../../interfaces/cuotas/EnumEstadoPago"
import { ICuota } from "../../../../interfaces/cuotas/ICuota"
import { TipoPagoEnum } from "../../../../interfaces/pago/EnumTipoPago"
import { IPago } from "../../../../interfaces/pago/IPago"
import { PropiedadTercero } from "../../../../interfaces/propiedades/PropiedadesTercero"
import { propiedadTerceroIniciales } from "../../../shared/datosFormularios/DatosInicialesFormularios"
import { useTercero } from "../../../shared/providers/TerceroProvider"
import { CuotaServicio } from "../../../../servicios/cuotaServicio"
import { CalcularCuota } from "../../../../utils/CalcularCuota"


// Interface de la propiedad
export interface Propiedad {
    interesDiario: number
    cantidadPagos: number
    pagoInicial: number
    porcentajePagoInicial: number
    porcentajeMora: number
    valorTotalLote: number
    valorFinanciar: number
    valorCuota: number
}

export interface IPagoColumna {
    cuotaId: string
    numeroCuota: number
    fecha: Date
    cuota: number
    interes: number
    capital: number
    abono: number
    mora: number
    saldo: number
    estadoPagoId: number
    tipoPagoId: number
    className?: string
    pagos: IPago[]
}

export interface IPagoInicialColumna {
    cuotaId: string
    fecha?: Date
    cuota: number
    mora: number
    estadoPagoId: number
    tipoPadoId: number
    numeroPago: number
    saldo: number
    className?: string
}

const propiedadInitial: Propiedad = {
    cantidadPagos: 0,
    pagoInicial: 0,
    porcentajePagoInicial: 0,
    porcentajeMora: 0,
    valorTotalLote: 0,
    valorFinanciar: 0,
    valorCuota: 0,
    interesDiario: 0
}

// Propiedades del provider
interface PropiedadProviderProps {
    propiedadTercero: PropiedadTercero // formulario de la propiedad
    propiedad: Propiedad // propiedad que contiene todos los datos calculados
    pagos: IPago[] // Total pagos realizados
    planPagos: IPagoColumna[] // Plan de pagos (cuotas normales)
    planPagosInicial: IPagoInicialColumna[] // Cuotas iniciales
    saldoRestante: number // Saldo restante
    totalMora: number // Total mora
    totalAbonos: number // Total abonos
    setPropiedadId: (propiedadId: string) => void
}

// Datos iniciales del provider
const initialFormularioProvider: PropiedadProviderProps = {
    propiedadTercero: propiedadTerceroIniciales,
    propiedad: propiedadInitial,
    pagos: [],
    planPagos: [],
    planPagosInicial: [],
    saldoRestante: 0,
    totalMora: 0,
    totalAbonos: 0,
    setPropiedadId: () => { }
}

// Creacion del context
const PropiedadProviderContext = createContext<PropiedadProviderProps>(initialFormularioProvider)

export const PropiedadProvider: React.FC<WithChildren> = ({ children }) => {

    const [getQP, setQP] = useSearchParams();

    // Datos del cliente
    const { propiedades, pagosPorPropiedad } = useTercero();

    // Estado para almacenar las Cuotas de la propiedad actual
    const [cuotas, setCuotas] = useState<ICuota[]>([]);

    // Formulario
    const [propiedadTercero, setPropiedadTercero] = useState<PropiedadTercero>(propiedadTerceroIniciales);

    // Actualizar la propiedad Id
    const setPropiedadId = useCallback((propiedadId: string) => {
        setPropiedadTercero(prop => ({ ...prop, propiedadId }));
    }, []);

    useEffect(() => {
        if (propiedades[0]?.propiedadId) setPropiedadId(propiedades[0].propiedadId)
    }, [propiedades]);

    // Cuando se cambia el id de la propiedad del formulario, busca la propiedad y actualiza los campos, tambien cambia el valor del query param
    useEffect(() => {
        const obtenerCuotas = async (propiedadTerceroId: string) => {
            const cuotas = await CuotaServicio.obtenerCuotas(propiedadTerceroId);
            setCuotas([...cuotas]);
        }

        if (propiedadTercero.propiedadId == '') return;
        const propiedad = propiedades.find(p => p.propiedadId == propiedadTercero.propiedadId);

        if (propiedad) {
            let currenParams = getQP;
            currenParams.set("propiedadTerceroId", propiedad.id);
            setQP(currenParams);

            obtenerCuotas(propiedad.id);
            const valorTotal = propiedad.areaPropiedad * propiedad.valorM2;
            setPropiedadTercero({ ...propiedad, valorTotal });
        }
    }, [propiedadTercero.propiedadId]);

    // Estado para calcular los valores del lote
    const propiedad: Propiedad = useMemo(() => {
        const {
            areaPropiedad,
            valorM2,
            interesAnual,
            cantidadPagos,
            pagoInicial,
            porcentajeMora
        } = propiedadTercero;

        const valorTotalLote = (areaPropiedad * valorM2);

        const porcentajePagoInicial = valorTotalLote == 0 ? 0 : (pagoInicial * 100) / valorTotalLote;
        const valorFinanciar = valorTotalLote - pagoInicial;

        const interesMensual = (interesAnual / 100) / 12;
        const interesDiario = (interesAnual / 100) / 360;
        const mora = porcentajeMora / 100;

        const valorCuota = CalcularCuota(valorFinanciar, interesMensual, cantidadPagos);

        return {
            cantidadPagos,
            interesDiario,
            pagoInicial,
            porcentajeMora: mora,
            porcentajePagoInicial,
            valorCuota,
            valorFinanciar,
            valorTotalLote
        }
    }, [propiedadTercero]);

    // Pagos de la propiedad actual seleccionada
    const pagos: IPago[] = useMemo(() => pagosPorPropiedad[propiedadTercero.id] ?? [], [propiedadTercero.id, pagosPorPropiedad]);

    // Total abonos
    const totalAbonos: number = useMemo(() =>
        pagos.reduce((p, c) => p + (c.tipoPagoId == TipoPagoEnum.abono ? c.abono : 0), 0)
        , [pagos]);

    // Plan de pagos
    const planPagos: IPagoColumna[] = useMemo(() => {
        const planPagos: IPagoColumna[] = cuotas.filter(c => c.tipoPagoId == TipoPagoEnum.cuota)
            .map(cuota => ({
                cuotaId: cuota.id,
                numeroCuota: cuota.numero,
                fecha: cuota.fecha,
                cuota: cuota.cuota,
                interes: cuota.interes,
                capital: cuota.capital,
                abono: cuota.abono,
                mora: cuota.mora,
                saldo: cuota.saldo,
                estadoPagoId: cuota.estadoPagoId,
                tipoPagoId: cuota.tipoPagoId,
                pagos: cuota.pagos ?? []
            }));
        for (let i = 0; i < planPagos.length; i++) {
            if (planPagos[i].estadoPagoId == EstadoPagoEnum.pendiente) {
                planPagos[i].estadoPagoId = EstadoPagoEnum.proximo;
                planPagos[i].className = "table-primary";
                break;
            }
        }
        return planPagos
    }, [cuotas]);

    // Plan de pagos inicial
    const planPagosInicial: IPagoInicialColumna[] = useMemo(() => {
        const planPagoInicial: IPagoInicialColumna[] = cuotas.filter(c => c.tipoPagoId == TipoPagoEnum.inicial)
            .map(cuota => ({
                cuotaId: cuota.id,
                fecha: cuota.fecha,
                cuota: cuota.cuota,
                mora: cuota.mora,
                estadoPagoId: cuota.estadoPagoId,
                numeroPago: cuota.numero,
                saldo: cuota.saldo,
                tipoPadoId: cuota.tipoPagoId
            }));
        for (let i = 0; i < planPagoInicial.length; i++) {
            if (planPagoInicial[i].estadoPagoId == EstadoPagoEnum.pendiente) {
                planPagoInicial[i].estadoPagoId = EstadoPagoEnum.proximo;
                planPagoInicial[i].className = "table-primary";
                break;
            }
        }
        return planPagoInicial
    }, [cuotas]);

    // Total Mora
    const totalMora: number = useMemo(() => planPagos.reduce((p, c) => p + c.mora, 0), [planPagos]);

    // saldo restante
    const saldoRestante: number = useMemo(() => {
        const saldoCuotasIniciales = planPagosInicial.length > 0
            ? planPagosInicial.reduce((p, c) => (c.estadoPagoId == EstadoPagoEnum.pendiente) ? p + c.cuota : p, 0)
            : propiedad.pagoInicial;
        const saldoCuotasNormales  = planPagos.length > 0 
            ? planPagos.reduce((p, c) => (c.estadoPagoId == EstadoPagoEnum.pendiente || c.estadoPagoId == EstadoPagoEnum.proximo) ? p + c.capital : p, 0)
            : propiedad.valorFinanciar;
        return saldoCuotasIniciales + saldoCuotasNormales;
    }, [
        propiedad.pagoInicial,
        propiedad.valorFinanciar,
        planPagosInicial,
        planPagos
    ]);

    return (
        <PropiedadProviderContext.Provider value={{
            propiedadTercero,
            propiedad,
            pagos,
            planPagos,
            planPagosInicial,
            saldoRestante,
            totalMora,
            totalAbonos,
            setPropiedadId
        }}>
            {children}
        </PropiedadProviderContext.Provider>
    )
}

export const usePropiedad = () => useContext(PropiedadProviderContext);
