import { FC } from "react"

interface TipoPagoProps {
    tipoPagoId: number
    className?: string
}

export const TipoPago: FC<TipoPagoProps> = ({
    tipoPagoId,
    className
}) => {

    switch (tipoPagoId) {
        case 1:
            return (
                <button className={`btn btn-sm nobtn-light-primary fw-bolder ms-2 py-1 px-3 ${className}`} style={{ fontSize: "10px" }}>
                    A CUOTA
                </button>
            )
        case 2:
            return (
                <button className={`btn btn-sm nobtn-light-success fw-bolder ms-2 py-1 px-3 ${className}`} style={{ fontSize: "10px" }}>
                    ABONO A CAPITAL
                </button>
            )
        case 3:
            return (
                <button className={`btn btn-sm nobtn-light-warning fw-bolder ms-2 py-1 px-3 ${className}`} style={{ fontSize: "10px" }}>
                    PAGO INICIAL
                </button>
            )
        default:
            return (
                <button className={`btn btn-sm nobtn-light-danger fw-bolder ms-2 py-1 px-3 ${className}`}>
                    N/A
                </button>
            )
    }
}