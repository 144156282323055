import date from "date-and-time";

export { }

declare global {
    interface Date {
        isLeapYear(): boolean;
        getDaysInMonth(): number;
        addMonths(value: number): Date;
        addDays(value: number): Date;
        restDays(value: number): Date;
        formato(): string
        formato2(): string
    }
}

Date.prototype.isLeapYear = function () { 
    const year = this.getFullYear();
    return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0)); 
};

Date.prototype.getDaysInMonth = function () {
    const year = this.getFullYear();
    const month = this.getMonth();
    return [31, (this.isLeapYear() ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
};

Date.prototype.addMonths = function (value) {
    var n = this.getDate();
    var date = new Date(this);
    date.setDate(1);
    date.setMonth(date.getMonth() + value);
    date.setDate(Math.min(n, date.getDaysInMonth()));
    return date;
};

Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

Date.prototype.restDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() - days);
    return date;
}

/**
 * YYYY-MM-DD
 */
Date.prototype.formato = function() {
    return date.format(this, "YYYY-MM-DD");
}

/**
 * DD-MM-YYYY
 */
Date.prototype.formato2 = function() {
    return date.format(this, "DD-MM-YYYY");
}