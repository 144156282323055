export { }

declare global {
    interface Number {
        formatoMoneda(currency?:boolean): string;
        formatoMoneda2(currency?:boolean): string;
        roundTo(places: number): number;
    }
    interface String {
        formatoMoneda(currency?:boolean): string;
        formatoMoneda2(currency?:boolean): string;
        isNumeric(): boolean
    }
}

Number.prototype.formatoMoneda = function (currency): string {
    const opciones = {
        maximumFractionDigits: 2,
    };
    return ((currency) ? "$" : "") + this.toLocaleString("en-US", opciones);
}

Number.prototype.formatoMoneda2 = function (currency): string {
    const opciones = {
        maximumFractionDigits: 0,
    };
    return ((currency) ? "$ " : "") + this.toLocaleString("es-ES", opciones);
}  

Number.prototype.roundTo = function (places: number) {
    return +(Math.round((this + "e+" + places) as unknown as number)  + "e-" + places);
}

String.prototype.formatoMoneda = function (currency): string {
    const opciones = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    };
    return (parseFloat(this.toString()) || 0).toLocaleString("es-ES", opciones) + ((currency) ? "$" : "");
}

String.prototype.formatoMoneda2 = function (currency): string {
    const opciones = {
        maximumFractionDigits: 0,
    };
    return ((currency) ? "$ " : "") + (parseFloat(this.toString()) || 0).toLocaleString("es-ES", opciones);
}

String.prototype.isNumeric = function() {
    return /^\d+$/.test(this.toString());
}