import {AuthModel, SendPinWhatsappCorreoModel} from './_models'
import * as jose from "jose";

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const SAVE_WHATSAPP_CORREO_LOCAL_STORAGE_KEY = 'saveSendPinWhatsappCorreo'

const getAuth = (): AuthModel | null => {
  if (!localStorage) {
    return null;
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return null;
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    
    if (auth) {
      const {exp} = jose.decodeJwt(auth.api_token);
      
      const current = Date.now()/1000;
      
      if(!exp || (exp && exp < current)){
        return null;
      }else{
        return auth
      }
    }
    return null;
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
    return null;
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

const getSavedSendPinWhatsappCorreo = (): SendPinWhatsappCorreoModel | null => {
  if (!localStorage) {
    return null
  }

  const lsValue: string | null = localStorage.getItem(SAVE_WHATSAPP_CORREO_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return null
  }

  try {
    const obj: SendPinWhatsappCorreoModel = JSON.parse(lsValue)
    if (obj) {
      for (const key in obj) {
        obj[key].dateExpired = new Date(obj[key].dateExpired)
      }
      return obj
    }
  } catch (error) {
    console.error('GET SEND PIN WHATSAPP CORREO LOCAL STORAGE PARSE ERROR', error)
  }
  return null
}

const setSaveSendPinWhatsappCorreo = (auth: SendPinWhatsappCorreoModel) => {
  if (!localStorage) {
    return null
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(SAVE_WHATSAPP_CORREO_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('SAVED SEND PIN WHATSAPP CORREO LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeSavedSendPinWhatsappCorreo = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(SAVE_WHATSAPP_CORREO_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('SEND PIN WHATSAPP CORREO LOCAL STORAGE REMOVE ERROR', error)
  }
}

export {
  getAuth, 
  setAuth, 
  removeAuth, 
  AUTH_LOCAL_STORAGE_KEY,
  setSaveSendPinWhatsappCorreo,
  getSavedSendPinWhatsappCorreo,
  removeSavedSendPinWhatsappCorreo
}
