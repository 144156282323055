import { FC } from "react";
import { TerceroPagoFormulario } from "./components/TerceroPagoFormulario";
import { TerceroPagoEstadisticas } from "./components/TerceroPagoEstadisticas";
import { TerceroPagoInicialLista } from "./components/TerceroPagoInicialLista";
import { TerceroPagoLista } from "./components/TerceroPagoLista";
import { PropiedadProvider } from "./providers/PropiedadProvider";

const PlanPagosPropiedades: FC = () => {

    return (
        <form
            className="form"
            noValidate
        >
            <div className="row mb-5">
                <div className="col-12 col-sm-7 mb-4">
                    <TerceroPagoFormulario />
                </div>
                <div className="col-12 col-sm-5">
                    <TerceroPagoEstadisticas />
                </div>
            </div>
            <TerceroPagoInicialLista className="mb-8" />
            <TerceroPagoLista />
        </form>
    )
}

export const PlanPagosPagina: FC = () => {

    return (
        <PropiedadProvider>
            <PlanPagosPropiedades/>
        </PropiedadProvider>
    )
}