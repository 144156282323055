import { useLocation, Link, useNavigate, useSearchParams } from 'react-router-dom'
import { toAbsoluteUrl, KTIcon, KTSVG } from '../../../_metronic/helpers'
import { useEffect, useMemo, useState } from 'react'
import { trunc } from '../../../utils/trunc'
import '../../../utils/formatos'
import { useAuth } from '../../modules/auth'
import SVG from 'react-inlinesvg'
import './ClienteHeader.css'
import { useTercero } from '../../shared/providers/TerceroProvider'

export const TerceroCuentaHeader: React.FC = () => {
  const location = useLocation()
  const { datosCliente, loading, logout } = useAuth()

  const { pagos, propiedades } = useTercero();

  const saldoTotalRestante = useMemo(() => propiedades.reduce((p, c) => p + c.valorTotal, 0), [propiedades]);
  const totalPagado = useMemo(() => pagos.reduce((p, c) => p + c.capital + c.abono, 0), [pagos]);
  const porcentajePagado = useMemo(() => (saldoTotalRestante == 0) ? 0 : (totalPagado * 100) / saldoTotalRestante, [saldoTotalRestante, totalPagado]);


  return (
    <div className='card mb-5 mb-xl-10 bg-light-secondary'>
      <div className='card-body pt-9 pb-0'>
        <div className='flex-grow-1 mb-3'>
          <div className='d-flex'>
            <div className='me-7 mb-4'>
              <div className='d-flex position-relative w-50px'>
                <SVG
                  src={toAbsoluteUrl('/media/icons/duotune/communication/com006.svg')}
                  width={'100%'}
                  height={'100%'}
                  className='text-primary'
                  title='iconoPerfil'
                />
                <div className='position-absolute translate-middle start-75 mt-4 ms-2 bg-success rounded-circle border border-3 border-light h-25 w-25'></div>
              </div>
            </div>
            {loading.datosCliente ? (
              <div data-kt-indicator='on'>
                <span className='indicator-progress'>
                  <span className='spinner-border spinner-border-sm align-middle m-5'></span>
                </span>
              </div>
            ) : (
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <a className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                      {`${datosCliente?.primerNombre} ${datosCliente?.primerApellido} ${datosCliente?.segundoApellido}`}
                    </a>
                    <a >
                      <KTIcon iconName='verify' className='fs-1 text-primary' />
                    </a>
                    {
                      datosCliente?.estadoId == 1 ? (
                        <a

                          className='btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3'
                        >
                          Activo
                        </a>

                      ) : (
                        <a

                          className='btn btn-sm btn-light-danger fw-bolder ms-2 fs-8 py-1 px-3'
                        >
                          Inactivo
                        </a>
                      )
                    }
                  </div>
                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <a

                      className='d-flex align-items-center text-gray-700 text-hover-primary me-5 mb-2'
                    >
                      <KTIcon iconName='profile-circle' className='fs-4 me-1' />
                      {datosCliente?.profesion ?? ' - '}
                    </a>
                    <a

                      className='d-flex align-items-center text-gray-700 text-hover-primary me-5 mb-2'
                    >
                      <KTIcon iconName='geolocation' className='fs-4 me-1' />
                      {datosCliente?.cargo ?? ' - '}
                    </a>
                    <a

                      className='d-flex align-items-center text-gray-700 text-hover-primary mb-2'
                    >
                      <KTIcon iconName='sms' className='fs-4 me-1' />
                      {datosCliente?.correo}
                    </a>
                  </div>
                </div>
                <div className='modal fade' tabIndex={-1} id='logout'>
                  <div className='modal-dialog'>
                    <div className='modal-content bg-light-secondary'>
                      <div className='modal-header'>
                        <h5 className='modal-title'>¿Desea cerrar sesión?</h5>
                        <div
                          className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                          data-bs-dismiss='modal'
                          aria-label='Close'
                        >
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr061.svg'
                            className='svg-icon svg-icon-2x'
                          />
                        </div>
                      </div>
                      <div className='modal-footer'>
                        <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                          Cancelar
                        </button>
                        <button
                          type='button'
                          className='btn btn-danger'
                          data-bs-dismiss='modal'
                          onClick={logout}
                        >
                          Cerrar sesión
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className='contenedor'>
            <div className='border border-gray-500 border-dashed rounded py-3 px-4'>
              <div className='d-flex align-items-center'>
                <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                <div className='fw-bolder'>{totalPagado.formatoMoneda2(true)}</div>
              </div>

              <div className='fw-bold fs-6 text-gray-600'>Pagado</div>
            </div>

            <div className='border border-gray-500 border-dashed rounded py-3 px-4'>
              <div className='d-flex align-items-center'>
                <KTIcon iconName='arrow-down' className='fs-3 text-danger me-2' />
                <div className='fw-bolder'>{saldoTotalRestante.formatoMoneda2(true)}</div>
              </div>

              <div className='fw-bold fs-6 text-gray-600'>Saldo</div>
            </div>

            <div className='border border-gray-500 border-dashed rounded py-3 px-4'>
              <div className='d-flex align-items-center'>
                <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                <div className='fw-bolder'>{trunc(porcentajePagado, 1)}%</div>
              </div>

              <div className='fw-bold fs-6 text-gray-600'>Porcentaje pagado</div>
            </div>
          </div>
        </div>

        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>

            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname.includes('/perfil') && 'active')
                }
                to={`/perfil`}
              >
                Perfil
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname.includes('/plan-pagos') && 'active')
                }
                to={`/plan-pagos`}
              >
                Plan de pagos
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname.includes('/pagos') && 'active')
                }
                to={`/pagos`}
              >
                Pagos
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
