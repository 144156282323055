import { FC } from "react"

interface EstadoProps {
    estadoPagoId: number
    className?: string
}
export const EstadoPago: FC<EstadoProps> = ({
    estadoPagoId,
    className
}) => {
    switch (estadoPagoId) {
        case 1:
            return (
                <span className={`btn btn-sm nobtn-light-warning fw-bolder ms-2 py-1 px-3 ${className}`}>
                    Pendiente
                </span>
            )
        case 2:
            return (
                <button className={`btn btn-sm nobtn-light-success fw-bolder ms-2 py-1 px-3 ${className}`}>
                    Pagado
                </button>
            )
        case 3:
            return (
                <button className={`btn btn-sm nobtn-light-danger fw-bolder ms-2 py-1 px-3 ${className}`}>
                    Rechazado
                </button>
            )
        default:
            return (
                <button className={`btn btn-sm nobtn-light-danger fw-bolder ms-2 py-1 px-3 ${className}`}>
                    N/A
                </button>
            )
    }
}