
import normalizar from "normalize-strings";
import { createContext, useState, useEffect, useContext, ReactNode, ReactElement, useMemo } from "react";

export type DataFilter = { [key: string]: any }


// Datos del useContext
interface ListViewContextData {
    data: any[]
    filterData: any[]
    setData: React.Dispatch<React.SetStateAction<any[]>>
    cargandoDatos: boolean
    setCargandoDatos: React.Dispatch<React.SetStateAction<boolean>>
    textFilter: string
    setTextFilter: React.Dispatch<React.SetStateAction<string>>
    addFilter: (key: string, data: any) => void
    removeFilters: (key: string) => void
}

// Datos iniciales del useContext
const initialListViewProvider: ListViewContextData = {
    data: [],
    filterData: [],
    setData: (d) => { },
    cargandoDatos: true,
    setCargandoDatos: () => { },
    textFilter: "",
    setTextFilter: () => { },
    addFilter: () => { },
    removeFilters: () => { }
}

const ListViewProviderContext = createContext<ListViewContextData>(initialListViewProvider)


interface ListViewProviderProps {
    children: ReactNode
    initialData: any[]
}

type ListViewProviderType = (props: ListViewProviderProps) => ReactElement


// Provider que me permite 'obtener' y filtrar cualquer datos para los datos para una data table
const ListViewProvider: ListViewProviderType = ({
    children,
    initialData
}) => {

    // Filtro de texto
    const [textFilter, setTextFilter] = useState("");

    // Filtros
    const [dataFilter, setDataFilter] = useState<DataFilter>({});

    // Variable para saber si esta cargando la informacion
    const [cargandoDatos, setCargandoDatos] = useState(true);

    // Datos iniciales de la data table (sobre este estado se filtra)
    const [data, setData] = useState<any[]>([]);
    // Datos filtrados que se mostraran en la data table
    //const [filterData, setFilterData] = useState<any[]>([]);

    // Filtrar cada vez que se cambia el estado de textFilter
    const filterData = useMemo(() => {
        // Se normaliza el texto y se pasa a minusculas para un mejor filtrado
        const textNormalizado = normalizar(textFilter).toLowerCase();
        const filtered = data.filter((t) => {
            // Se filtra por todos los datos de la tabla
            let nombreNormalizado = normalizar(Object.values(t).join("")).toLowerCase();
            const math1 = nombreNormalizado.includes(textNormalizado);
            var marth2 = true;
            Object.entries(dataFilter).forEach(([key, data]) => {
                try {
                    if (t[key] != data) {
                        marth2 = false;
                    }
                } catch (err) {  }
            });
            setCargandoDatos(false);
            return math1 && marth2;
        });
        return filtered;
    }, [data, textFilter, dataFilter]);

    const filterDataPague = useMemo(() => {
        
    }, [filterData]);

    useEffect(() => {
        setCargandoDatos(false);
    }, [data]);

    useEffect(() => {
        setData(initialData);
    }, [initialData]);

    const addFilter = (key: string, data: any) => {
        setDataFilter((f) => ({ ...f, [key]: data }));
    }

    const removeFilters = (key: string) => {
        setDataFilter((f) => {
            delete f[key];
            return { ...f };
        })
    }

    return (
        <ListViewProviderContext.Provider value={{
            data,
            filterData,
            setData,
            cargandoDatos,
            setCargandoDatos,
            textFilter,
            setTextFilter,
            addFilter,
            removeFilters
        }}>
            {children}
        </ListViewProviderContext.Provider>
    )
}

// custom hook para obtener los datos del contexto
const useListView = () => useContext(ListViewProviderContext);

export {
    ListViewProvider,
    useListView
}
