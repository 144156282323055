import { Worksheet } from "exceljs";
import { mergeCells } from "./mergeCells";
import { Pos } from "./estadoCuenta/types";

export const copyCells = (wsBase: Worksheet, ws: Worksheet, from: Pos, to: Pos, copyTo: Pos) => {
    mergeCells(wsBase, ws, from, to, copyTo);
    for (let i = from.y, ii = copyTo.y; i <= to.y; i++, ii++) {
        for (let j = from.x, jj = copyTo.x; j <= to.x; j++, jj++) {
            const cell = wsBase.getRow(i).getCell(j);
            let newCell = ws.getRow(ii).getCell(jj);
            newCell.style = cell.style
            try {
                newCell.value = cell.value
            } catch (err) {

            }
        }
    }
}