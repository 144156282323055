import {FC, createContext, useContext, useEffect, useState} from 'react'
import {WithChildren} from '../../../../_metronic/helpers'
import {UbicacionModelo} from '../../../../interfaces/UbicacionModelo'
import {InformacionServicios} from '../../../../servicios/ubicacionServicios'
interface InformacionProviderProps {
  municipios: UbicacionModelo[]
}

const initialInformacionProvider: InformacionProviderProps = {
  municipios: [],
}

const InformacionProviderContext = createContext<InformacionProviderProps>(
  initialInformacionProvider
)

const InformacionProvider: FC<WithChildren> = ({children}) => {
  const [municipios, setMunicipios] = useState<UbicacionModelo[]>([])

  const obtenerMunicipios = async () => {
    try {
      const municipios = await InformacionServicios.ObtenerUbicaciones()
      setMunicipios(municipios)
    } catch (err) {}
  }

  useEffect(() => {
    obtenerMunicipios()
  }, [])

  return (
    <InformacionProviderContext.Provider
      value={{
        municipios,
      }}
    >
      {children}
    </InformacionProviderContext.Provider>
  )
}

const useInformacion = () => useContext(InformacionProviderContext)

export {InformacionProvider, useInformacion}
