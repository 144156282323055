import React, {useState, useEffect, useRef, ChangeEvent, MouseEvent} from 'react'
import './FormSelectSearch.css'
import clsx from 'clsx'

interface Option {
  label: string
  value: number | string
}

interface CustomSelectProps {
  id: string
  options: Option[]
  form: any
  value: number
  validProp: boolean | undefined
  error: string
  classContainer: string
}

const FormSelectSearch: React.FC<CustomSelectProps> = ({
  id,
  options,
  form,
  value,
  error = false,
  classContainer,
  validProp,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [filteredOptions, setFilteredOptions] = useState<Option[]>([])
  const [selectValue, setSelectedValue] = useState<Option>({label: '-', value})
  const inputRef = useRef<HTMLInputElement | null>(null)
  const dropdownRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (options.length) {
      const municipioName = options.find((option) => option.value === value) as Option | undefined
      if (municipioName) {
        setSearchValue(municipioName.label)
      }
    }
    setIsOpen(false)
    // eslint-disable-next-line
  }, [selectValue, options])
  //cerrar listado
  useEffect(() => {
    const handleClickOutside = (e: any) => {
      const event = e as MouseEvent
      if (
        dropdownRef.current &&
        inputRef.current !== document.activeElement &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false)
      }
    }
    const handleClickDownside = (e: any) => {
      const event = e as MouseEvent
      if (
        dropdownRef.current &&
        inputRef.current !== document.activeElement &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('mouseup', handleClickDownside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('mouseup', handleClickDownside)
    }
  }, [])
  //filtrado/busqueda
  useEffect(() => {
    let filtered: Option[]
    filtered = options.filter(
      (option) => buscar(searchValue, option.label) || !searchValue.length
    ) as Option[]
    let isSelectOpcion
    if (value && filtered.length) {
      isSelectOpcion = buscar(
        value.toString(),
        filtered[0].value.toString() /* si tienen coincidencias con el seleccionado previamente
         y el la longitud igual a 1, mostrar todo */
      )
    }
    setFilteredOptions(filtered.length === 1 && isSelectOpcion ? [...options] : [...filtered])

    // eslint-disable-next-line
  }, [options, searchValue, isOpen])

  const toggleDropdown = () => {
    if (!isOpen) {
      setIsOpen(true)
      if (inputRef.current) {
        inputRef.current.select() //seleccionar todo el texto en el input
      }
      setTimeout(() => {
        if (value && dropdownRef.current) {
          const selectedOption = dropdownRef.current.querySelector(`[data-value="${value}"]`)
          if (selectedOption) {
            selectedOption.scrollIntoView({
              behavior: 'auto',
              block: 'center',
            }) // Puedes ajustar el comportamiento de desplazamiento según tu preferencia
          }
        }
      }, 10)
    }
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!isOpen) setIsOpen(true)
    setSearchValue(e.target.value)
  }

  const handleSelectOption = (option: Option) => {
    setSelectedValue(option)
    form.setFieldValue(id, option.value)
  }
  const handleDropdownClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
  }
  const handleKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    const key = evt.key
    if (key === 'Enter') {
      if (filteredOptions.length === 1) {
        handleSelectOption(filteredOptions[0])
      }
    }
  }
  const buscar = (textoABuscar: string, texto: string): boolean => {
    // Normalizar ambos textos a Unicode NFD
    const textoSinTildes = textoABuscar.normalize('NFD')
    const textoNormalizado = texto.normalize('NFD')

    // Reemplazar los caracteres acentuados con caracteres sin acentuar
    const textoSinTildesSinAcentos = textoSinTildes.replace(/[\u0300-\u036f]/g, '')
    const textoNormalizadoSinAcentos = textoNormalizado.replace(/[\u0300-\u036f]/g, '')

    // Crear una expresión regular con la cadena de búsqueda
    const expresionRegular = new RegExp(textoSinTildesSinAcentos, 'gi')

    // Buscar el texto en el texto normalizado (sin tildes ni acentos)
    return expresionRegular.test(textoNormalizadoSinAcentos)
  }
  return (
    <div className={classContainer}>
      <div className='custom-select' onClick={toggleDropdown}>
        <input
          className={clsx(
            'form-select bg-transparent',
            {'is-invalid': validProp && error},
            {
              'is-valid': validProp && !error,
            }
          )}
          type='text'
          ref={inputRef}
          value={searchValue}
          onChange={handleInputChange}
          onKeyDown={(e) => handleKeyDown(e)}
        />
        {
          <div
            className={`custom-select-options bg-secondary border-bg-primary ${
              isOpen ? 'selected' : ''
            }`}
            ref={dropdownRef}
            onClick={handleDropdownClick}
          >
            {filteredOptions.map((option) => (
              <div
                key={option.value}
                className={`custom-select-option ${value === option.value ? 'selected' : ''}`}
                onClick={(e) => handleSelectOption(option)}
                data-value={option.value}
              >
                {option.label}
              </div>
            ))}
          </div>
        }
      </div>
    </div>
  )
}

export {FormSelectSearch}
