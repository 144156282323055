import {FC} from 'react'
import {useFormulario} from '../providers/FormularioProvider'
import {useInformacion} from '../providers/InformacionProvider'
import {FormularioCampo} from './FormularioLayout'
import {FormularioInput} from '../../../../_metronic/partials/formulario/formulario'
import {FormSelectSearch} from './FormSelectSearch'

export const TerceroDireccion: FC = () => {
  const {formik} = useFormulario()
  const {municipios} = useInformacion()

  return (
    <div className='card mb-5 mb-xl-10 bg-light-secondary'>
      <div className='card-header border-0'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Dirección</h3>
        </div>
      </div>
      <div id='kt_crear_tercero_direccion' className='collapse show'>
        <div className='card-body border-top p-9 row row-cols-2'>
          <FormularioCampo nombre='Municipio'>
            <div className='row p-0'>
              <FormSelectSearch
                id={'municipioId'}
                options={municipios.map((m) => ({label: m.nombreConjunto, value: m.id}))}
                form={formik}
                value={formik.getFieldProps('municipioId').value}
                validProp={formik.touched.municipioId}
                error={formik.errors.municipioId ? formik.errors.municipioId : ''}
                classContainer='fv-row mb-2 col p-1'
              />
            </div>
          </FormularioCampo>

          <FormularioCampo nombre='Barrio'>
            <div className='row p-0'>
              <FormularioInput
                placeholder={'Barrio'}
                props={formik.getFieldProps('barrio')}
                validProp={formik.touched.barrio}
                error={formik.errors.barrio}
              />
            </div>
          </FormularioCampo>

          <FormularioCampo nombre='Dirección'>
            <div className='row p-0'>
              <FormularioInput
                placeholder={'Direccion'}
                requerido
                props={formik.getFieldProps('direccion')}
                validProp={formik.touched.direccion}
                error={formik.errors.direccion}
              />
            </div>
          </FormularioCampo>
        </div>
      </div>
    </div>
  )
}
