/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect } from 'react'
import { IPagoColumna, usePropiedad } from '../providers/PropiedadProvider'
import date from "date-and-time"
import { KTIcon } from '../../../../_metronic/helpers'
import { EstadoPagoEnum } from '../../../../interfaces/cuotas/EnumEstadoPago'
import { BotonIcon } from '../../../shared/misc/BotonOpenModal'

type Props = {
    className?: string
}

export const TerceroPagoLista: React.FC<Props> = ({
    className = ""
}) => {

    const { planPagos, propiedad } = usePropiedad();

    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Plan de pagos</span>
                </h3>
                {
                    /*
                        <button
                            type='button'
                            className='btn btn-sm btn-light-primary'
                            style={{ maxHeight: "40px" }}
                            onClick={() => navigate(`/clientes/profile/pagos/?idCliente=${tercero?.id}&propiedadTerceroId=${formik.values.id}`)}
                        >
                            <i className="fs-2 bi bi-cash-coin"></i>
                            Agregar Pago
                        </button>
                    */
                }
            </div>
            {/* end::Header */}

            {/* begin::Body */}
            <div className='card-body py-3'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                        {/* begin::Table head */}
                        <thead>
                            <tr className='fw-bold text-muted'>
                                <th style={{ width: "10px" }}></th>
                                <th style={{ width: "10px" }}>No</th>
                                <th className='min-w-80px' style={{ textAlign: "center" }}>Fecha</th>
                                <th className='min-w-100px'>Cuota Mensual</th>
                                <th className='min-w-100px'>Interes</th>
                                <th className='min-w-100px'>Capital</th>
                                <th className='min-w-100px'>Abono</th>
                                <th className='min-w-100px'>Mora</th>
                                <th className='min-w-100px'>Saldo</th>
                                <th className='min-w-100px'>Estado</th>
                            </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody style={{ fontSize: "12px" }}>
                            <tr>
                                <th></th>
                                <th>
                                    <span className='text-dark fw-bold text-hover-primary' style={{ cursor: "default" }}>
                                        0
                                    </span>
                                </th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th>
                                    <span className='text-dark fw-bold text-hover-primary' style={{ cursor: "default" }}>
                                        {propiedad.valorFinanciar.formatoMoneda2(true)}
                                    </span>
                                </th>
                                <th></th>
                            </tr>
                            {
                                planPagos.map((pago, i) => <TerceroPagoRow key={i} pago={pago} />)
                            }
                        </tbody>
                        {/* end::Table body */}
                    </table>
                    {/* end::Table */}
                </div>
                {/* end::Table container */}
            </div>
            {/* begin::Body */}
        </div>
    )
}

interface TerceroPagoRowProp {
    pago: IPagoColumna
}

const TerceroPagoRow: FC<TerceroPagoRowProp> = ({
    pago
}) => {
    return (
        <tr className={pago.className ?? ""}>
            <td></td>
            <td>
                <span className='text-dark fw-bold text-hover-primary' style={{ cursor: "default" }}>
                    {pago.numeroCuota}
                </span>
            </td>
            <td style={{textAlign: "center"}}>
                <span className='text-dark fw-bold text-hover-primary' style={{ cursor: "default" }}>
                    {date.format(pago.fecha, "DD/MM/YYYY")}
                </span>
            </td>
            <td>
                <span className='text-dark fw-bold text-hover-primary' style={{ cursor: "default" }}>
                    {pago.cuota.formatoMoneda2(true)}
                </span>
            </td>
            <td>
                <span className='text-dark fw-bold text-hover-primary' style={{ cursor: "default" }}>
                    {pago.interes.formatoMoneda2(true)}
                </span>
            </td>
            <td>
                <span className='text-dark fw-bold text-hover-primary' style={{ cursor: "default" }}>
                    {pago.capital.formatoMoneda2(true)}
                </span>
            </td>
            <td>
                <span className='text-dark fw-bold text-hover-primary' style={{ cursor: "default" }}>
                    {pago.abono.formatoMoneda2(true)}
                </span>
            </td>
            <td>
                <span className={`${(pago.mora > 0 && pago.estadoPagoId != EstadoPagoEnum.pagado) ? "text-danger" : "text-dark"} fw-bold text-hover-primary`} style={{ cursor: "default" }}>
                    {pago.mora.formatoMoneda2(true)}
                </span>
            </td>
            <td>
                <span className='text-dark fw-bold text-hover-primary' style={{ cursor: "default" }}>
                    {pago.saldo.formatoMoneda2(true)}
                </span>
            </td>
            <td>
                {
                    (pago.estadoPagoId == EstadoPagoEnum.pagado) ? 
                        <i style={{fontSize: "12px"}} className="bi bi-patch-check-fill text-success"><span style={{fontSize: "12px", fontStyle: "initial"}}> Pagado</span></i>
                    :  (pago.estadoPagoId == EstadoPagoEnum.proximo) ? 
                        <i style={{fontSize: "12px"}} className="bi bi-patch-exclamation-fill text-info"><span style={{fontSize: "12px", fontStyle: "initial"}}> Proximo</span></i> 
                    : ""
                }
            </td>
        </tr >
    )
}