import { FC, createContext, useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { IPago } from "../../../../interfaces/pago/IPago";
import { WithChildren } from "../../../../_metronic/helpers";
import { InputInformativo } from "../../../shared/misc/InputInformativo";

type showModalType = (show: boolean, pago: IPago) => void
interface ModalValoresPros {
    showModal: showModalType
}

const ModalValoresIncial: ModalValoresPros = {
    showModal: () => { }
}

const ModalValoresContext = createContext<ModalValoresPros>(ModalValoresIncial)

const ModalValoresProvider: FC<WithChildren> = ({
    children
}) => {
    const [show, setShow] = useState(false);
    const [pago, setPago] = useState<IPago | undefined>();

    const showModal: showModalType = (show, pago) => {
        setShow(show);
        setPago(pago);
    }

    return (
        <ModalValoresContext.Provider value={{
            showModal
        }}>
            {children}
            {
                show &&
                <Modal show={show} className="fade modal modal">
                    <form
                        className="form"
                        noValidate
                    >
                        <Modal.Header className="p-4">
                            <h2 className="modal-title">{pago?.propiedad} - {pago?.referenciaPago}</h2>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                aria-label="Close"
                                onClick={() => setShow(false)}
                            >
                                <i className="bi bi-x-lg"></i>
                            </div>
                        </Modal.Header>
                        <Modal.Body className="bg-gray-300 px-6 py-4">
                            {/* begin::modal */}
                            <div className="row row-cols-1 bg-light border rounded-3">
                                <div className="col-12 p-0 px-5 rounded mb-2">
                                    <h1 className="align-middle">Intereses</h1>
                                </div>
                                <InputInformativo
                                    icon="bi-person-video2"
                                    nombre="Interes"
                                    text={pago?.interes.formatoMoneda2(true)}
                                />
                                <InputInformativo
                                    icon="bi-telephone-fill"
                                    nombre="Capital"
                                    text={pago?.capital.formatoMoneda2(true)}
                                />
                                <InputInformativo
                                    icon="bi-whatsapp"
                                    nombre="Abono"
                                    text={pago?.abono.formatoMoneda2(true)}
                                />
                            </div>
                            {/* end::modal */}
                        </Modal.Body>
                        <Modal.Footer className="p-4">

                        </Modal.Footer>
                    </form>
                </Modal >
            }
        </ModalValoresContext.Provider>
    )
}

const useModalValores = () => useContext(ModalValoresContext);

export {
    ModalValoresProvider as ModalValoresProvider,
    useModalValores
}
