import {Navigate, Route, Routes} from 'react-router-dom'
import {Login} from './components/Login'
import {AuthLayout} from './AuthLayout'
import {ValidPin} from './components/ValidPin'
import {useAuth} from './core/Auth'
import {useState} from 'react'

const AuthPage = () => {
  const {pin} = useAuth()
  const [typePin, setTypePin] = useState<'whatsapp' | 'correo'>('whatsapp')
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path='login' element={<Login />} />
        <Route
          path='valid-pin'
          element={pin ? <ValidPin type={typePin} setType={setTypePin} /> : <Navigate to='/auth' />}
        />
        <Route index element={<Login />} />
      </Route>
    </Routes>
  )
}

export {AuthPage}
