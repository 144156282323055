import { FC, useMemo } from "react";
import { usePropiedad } from "../providers/PropiedadProvider";
import { useTercero } from "../../../shared/providers/TerceroProvider";
import { FormularioSelect, InputNoEditable } from "../../../../_metronic/partials/formulario/formulario";
import { SelectAdd } from "../../../../_metronic/partials/formulario/Select";
import { PeriodoEnum } from "../../../shared/datosFormularios/Informacion";

export const TerceroPagoFormulario: FC = () => {

    const { propiedadTercero, propiedad, setPropiedadId } = usePropiedad();
    const { propiedades } = useTercero();

    const periodo: string = useMemo(() => {
        switch (propiedadTercero.periodoId) {
            case PeriodoEnum.Mensual:
                return "Mensual";
            case PeriodoEnum.Bimensual:
                return "Bimensual";
            case PeriodoEnum.Trimestral:
                return "Trimestral";
            case PeriodoEnum.Cuatrimestral:
                return "Cuatrimestral";
            case PeriodoEnum.Semestreal:
                return "Semestral";
            default:
                return "N/A";
        }
    }, [propiedadTercero.periodoId]);

    return (
        <>
            <div className="card p-0 mb-3">
                <div className='card-body border-top py-3 px-9'>
                    <div className="row row-cols-2">
                        <SelectAdd
                            className="col-12"
                            options={propiedades.map(p => ({
                                nombre: p.descripcion,
                                opcion: p.descripcion,
                                valor: p.propiedadId
                            }))}
                            props={{
                                name: "",
                                onBlur: () => {},
                                onChange: (e: React.ChangeEvent<HTMLSelectElement>) => {
                                    setPropiedadId(e.target.value)
                                },
                                value: propiedadTercero.propiedadId
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className='card p-0'>
                <div className='card-body border-top py-3 px-9'>
                    <div className="row">
                        <InputNoEditable
                            className="col-6"
                            nombre="Fecha limite pago inicial "
                            type="date"
                            value={propiedadTercero.fechaLimitePagoInicial}
                        />
                        <InputNoEditable
                            className="col-6"
                            nombre="Fecha inicio financiación"
                            type="date"
                            value={propiedadTercero.fechaInicioFinanciacion}
                        />
                        <InputNoEditable
                            className="col-6"
                            nombre={"Área"}
                            value={propiedadTercero.areaPropiedad.formatoMoneda2(true)}
                        />
                        <InputNoEditable
                            className="col-6"
                            nombre={"Total sin intereses"}
                            value={propiedad.valorTotalLote.formatoMoneda2(true)}
                        />
                        <InputNoEditable
                            className="col-6"
                            nombre={"Interés anual %"}
                            value={propiedadTercero.interesAnual}
                        />
                        <InputNoEditable
                            className="col-6"
                            nombre={"Porcentaje mora %"}
                            value={propiedadTercero.porcentajeMora.formatoMoneda2(true)}
                        />
                        <InputNoEditable
                            className="col-6"
                            nombre="Periodo de pago"
                            value={periodo}
                        />
                        <InputNoEditable
                            className="col-6"
                            nombre="Cantidad de pagos"
                            value={propiedadTercero.cantidadPagos.formatoMoneda2(true)}
                        />
                        <InputNoEditable
                            className="col-6"
                            nombre={"Pago inicial"}
                            value={propiedadTercero.pagoInicial.formatoMoneda2(true)}
                        />
                        <InputNoEditable
                            className="col-6"
                            nombre={"Valor por m2"}
                            value={propiedadTercero.valorM2.formatoMoneda2(true)}
                        />
                    </div>

                </div>
            </div>
        </>

    )
}