import { PagoServicio } from "../../../../servicios/pagoServicio";
import { useAuth } from "../../../modules/auth";
import { BotonActualizar } from "../../../shared/misc/BotonActualizar";
import { BotonFiltro } from "../../../shared/misc/BotonFiltro";
import { useListView } from "../../../shared/providers/ListView/ListView";
import { PropiedadesDropDownFilter } from "./PropiedadesDropDownFilter";


export const ListaPagosToolbar = () => {

    const { datosCliente } = useAuth();
    const { setData, setCargandoDatos } = useListView();

    const obtenerPagos = async () => {
        if (!datosCliente) return;
        setCargandoDatos(true);
        const pagos = await PagoServicio.obtenerPagos(datosCliente.id);
        setData(pagos);
    }

    return (
        <div className='d-flex justify-content-end align-items-center' data-kt-user-table-toolbar='base'>
            <div className='m-0'>
                <BotonActualizar onClick={obtenerPagos} />
            </div>
            <div className='m-0'>
                <BotonFiltro />
                <PropiedadesDropDownFilter />
            </div>
        </div>
    )
}