import { addressToPos } from "../addresToPos";

export const pos_nombre = "H5";
export const pos_documento = "H6";
export const pos_direccion = "H7";
export const pos_unidad = "H8";
export const pos_telefonos = "H9";
export const pos_correo = "H10";

export const pos_pagina = addressToPos("D32");
export const pos_fecha = addressToPos("F32");
export const pos_hora = addressToPos("J32");
export const pos_reporte = addressToPos("L32");

export const por_valor_total = addressToPos("L26");
export const pos_valor_financiar = addressToPos("L27");
export const pos_total_recibido = addressToPos("L28");
export const pos_cuotas_mora = addressToPos("L29");
export const pos_saldo_final = addressToPos("L30");

export const pos_header = {
    from: {
        x: 1, y: 1
    },
    to: {
        x: 16, y: 11
    }
};

export const pos_footer = {
    from: {
        x: 1, y: 25
    },
    to: {
        x: 16, y: 33
    }
};

export const pos_pi_col = {
    from: {
        x: 1, y: 12
    },
    to: {
        x: 16, y: 13
    }
};

export const pos_pn_col = {
    from: {
        x: 1, y: 16
    },
    to: {
        x: 16, y: 17
    }
};

export const pos_mv_col = {
    from: {
        x: 1, y: 20
    },
    to: {
        x: 16, y: 21
    }
};

export const pos_pi_lista_1 = {
    from: {
        x: 1, y: 14
    },
    to: {
        x: 16, y: 14
    }
};

export const pos_pi_lista_2 = {
    from: {
        x: 1, y: 15
    },
    to: {
        x: 16, y: 15
    }
};

export const pos_pn_lista_1 = {
    from: {
        x: 1, y: 18
    },
    to: {
        x: 16, y: 18
    }
};

export const pos_pn_lista_2 = {
    from: {
        x: 1, y: 19
    },
    to: {
        x: 16, y: 19
    }
};

export const pos_mv_lista_1 = {
    from: {
        x: 1, y: 22
    },
    to: {
        x: 16, y: 22
    }
};

export const pos_mv_lista_2 = {
    from: {
        x: 1, y: 23
    },
    to: {
        x: 16, y: 23
    }
};

