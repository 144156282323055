import {BotonGuardar} from './components/BotonGuardar'
import {InformacionProvider} from './providers/InformacionProvider'
import {FormularioProvider} from './providers/FormularioProvider'
import {TerceroDatosContacto} from './components/TerceroDatosContacto'
import {TerceroDireccion} from './components/TerceroDireccion'

export function TerceroActualizarPagina() {
  return (
    <InformacionProvider>
      <FormularioProvider>
        <TerceroDatosContacto />
        <TerceroDireccion />
        <BotonGuardar />
      </FormularioProvider>
    </InformacionProvider>
  )
}
