import { DatosClienteModel, DatosPersonales } from './TerceroModelo'
export interface Informacion {
    nombre: string
    valor: string | number
}
export const documentos: Informacion[] = [
    {
        nombre: 'CC',
        valor: 1,
    },
    {
        nombre: 'Pasaporte',
        valor: 2,
    },
]
export const estadoCivil: Informacion[] = [
    {
        nombre: 'Soltero',
        valor: 1,
    },
    {
        nombre: 'Casado',
        valor: 2,
    },
    {
        nombre: 'Unión de hecho',
        valor: 3,
    },
    {
        nombre: 'Divorciado',
        valor: 4,
    },
    {
        nombre: 'Viudo',
        valor: 5,
    },
]
export const mascotas: Informacion[] = [
    {
        nombre: 'Perro',
        valor: 'Perro',
    },
    {
        nombre: 'Gato',
        valor: 'Gato',
    },
    {
        nombre: 'Hámster',
        valor: 'Hamster',
    },
    {
        nombre: 'Pájaro',
        valor: 'Pajaro',
    },
    {
        nombre: 'Pez',
        valor: 'Pez',
    },
]
export const datosPersonalesIniciales: DatosPersonales = {
    tipoDocumentoId: 1,
    identificacion: '',
    primerNombre: '',
    otrosNombres: '',
    primerApellido: '',
    segundoApellido: '',
    profesion: '',
    cargo: '',
    codigoActividad: 1,
    estadoCivilId: 1,
    municipioId: 0,
    barrio: '',
    direccion: '',
    contacto: '',
    correo: '',
    estadoId: 1,
}
export const datosTerceroIniciales: DatosClienteModel = {
    id: '',
    ...datosPersonalesIniciales,
    contactoWhatsapp: '',
    nombreComercial: '',
    alias: '',
    hijos: [],
    mascotas: [],
    pareja: datosPersonalesIniciales,
}