import { Dispatch, FC, SetStateAction, createContext, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom"
import { WithChildren } from "../../../_metronic/helpers";
import { PagoServicio } from "../../../servicios/pagoServicio";
import { TercerosServicios } from "../../../servicios/tercerosServicios";
import { IPago } from "../../../interfaces/pago/IPago";
import { PropiedadTercero } from "../../../interfaces/propiedades/PropiedadesTercero";
import { DatosClienteModel } from "../../../interfaces/TerceroModelo";
import { useAuth } from "../../modules/auth";
import { EstadoPagoEnum } from "../../../interfaces/cuotas/EnumEstadoPago";

interface TerceroProviderProps {
    pagos: IPago[] // Lista de todos los pagos del tercero
    setPagos: Dispatch<SetStateAction<IPago[]>> // funcion set para cambiar la lista de pagos
    propiedades: PropiedadTercero[] // lista de todas las propiedades del tercero
    setPropiedades: Dispatch<SetStateAction<PropiedadTercero[]>> // funcion set para cambiar la lista de pagos
    estado: number // estado del tercero
    setEstado: React.Dispatch<React.SetStateAction<number>> // funcion set para cambiar el estadp del tercero
    pagosPorPropiedad: { [key: string]: IPago[] }
}

const initialTerceroProvider: TerceroProviderProps = {
    pagos: [],
    setPagos: () => { },
    propiedades: [],
    setPropiedades: () => { },
    estado: 0,
    setEstado: () => { },
    pagosPorPropiedad: {}
}

const TerceroProviderContext = createContext<TerceroProviderProps>(initialTerceroProvider)

const TerceroProvider: FC<WithChildren> = ({ children }) => {

    const [get, set] = useSearchParams();
    const { datosCliente } = useAuth();
    const [propiedades, setPropiedades] = useState<PropiedadTercero[]>([]);
    const [pagos, setPagos] = useState<IPago[]>([]);
    const [estado, setEstado] = useState(0);
    const navigate = useNavigate();

    const [enMora, setEnMora] = useState(false);

    const pagosPorPropiedad = useMemo<{ [key: string]: IPago[] }>(() => {
        const movs: { [key: string]: IPago[] } = {};
        pagos.forEach((mov) => {
            if (movs[mov.propiedadTerceroId]) movs[mov.propiedadTerceroId].push(mov);
            else movs[mov.propiedadTerceroId] = [mov]
        });
        return movs;
    }, [pagos]);

    const obtenerDatosTercero = async () => {
        if (datosCliente) {
            let propiedades = await TercerosServicios.ObtenerPropiedades(datosCliente.id);

            let pagos = (await PagoServicio.obtenerPagos(datosCliente.id)).filter(p => p.estadoPagoId != EstadoPagoEnum.pendiente)

            setPropiedades(propiedades);
            setPagos(pagos);
        }
    }

    useEffect(() => {
        obtenerDatosTercero();
    }, [datosCliente]);

    return (
        <TerceroProviderContext.Provider value={{
            pagos,
            setPagos,
            propiedades,
            setPropiedades,
            estado,
            setEstado,
            pagosPorPropiedad
        }}>
            {children}
        </TerceroProviderContext.Provider>
    )
}

const useTercero = () => useContext(TerceroProviderContext);

export {
    TerceroProvider,
    useTercero
}
