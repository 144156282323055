import { FC, useMemo, useState } from "react"
import clsx from "clsx";
import { KTIcon } from "../../../../_metronic/helpers";
import { useLayout } from "../../../../_metronic/layout/core";
import { Informacion } from "../../../shared/datosFormularios/Informacion";
import { useListView } from "../../../shared/providers/ListView/ListView";
import { useTercero } from "../../../shared/providers/TerceroProvider";

export const PropiedadesDropDownFilter: FC = () => {

    const { config } = useLayout()
    const daterangepickerButtonClass = config.app?.toolbar?.fixed?.desktop
        ? 'btn-light'
        : 'bg-body btn-color-gray-700 btn-active-color-primary'
        
    const { addFilter, removeFilters } = useListView();

    const [propiedad, setPropiedad] = useState("");
    const { pagos } = useTercero();

    const propiedades = useMemo<Informacion[]>(() => {
        const prop: {[key: string]: string} = {};
        pagos.forEach(p => {
            if(!prop[p.propiedadTerceroId]){
                prop[p.propiedadTerceroId] = p.propiedad!;
            }
        });
        return Object.entries(prop).map(([k, v]) => ({nombre: v, valor: k}));
    }, [pagos]);

    const cambiarFiltros = () => {
        if (propiedad === "") removeFilters("propiedadId");
        else addFilter("propiedadId", propiedad);
    }

    const quitarFiltros = () => {
        setPropiedad("");
    }

    return (
        <div className='menu menu-sub menu-sub-dropdown w-200px w-md-250px' data-kt-menu='true'>
            <div className='px-7 py-5'>
                <div className='fs-5 text-dark fw-bolder'>Filtros</div>
            </div>

            <div className='separator border-gray-200'></div>

            <div className='px-7 py-5'>
                <div className='mb-auto'>
                    <label className='form-label fw-bold'>Propiedad:</label>

                    <div className='fv-row mb-2 col p-1'>
                        <div className="form-check">
                            {
                                propiedades.map((prop, i) => (
                                    <div className="mb-3" key={i}>
                                        <input
                                            onChange={(e) => setPropiedad(e.target.value)}
                                            checked={propiedad === prop.valor}
                                            name="filter_rol"
                                            className="form-check-input"
                                            type="radio"
                                            value={prop.valor}
                                        />
                                        <span>{prop.nombre}</span>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>

                <div className='d-flex justify-content-end'>
                    <button type='button' className={clsx('btn btn-sm btn-light btn-active-light-primary me-2', daterangepickerButtonClass)} onClick={quitarFiltros}>
                        <KTIcon iconName='arrow-circle-right' className='fs-1' />
                        Limpiar
                    </button>
                    <button
                        type='submit'
                        className='btn btn-sm btn-primary'
                        data-kt-menu-dismiss='true'
                        onClick={cambiarFiltros}
                    >
                        Aplicar
                    </button>
                </div>
            </div>
        </div>
    )
}