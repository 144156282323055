/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import SVG from 'react-inlinesvg'

const HeaderUserMenu: FC = () => {
  const location = useLocation()
  const {datosCliente, loading} = useAuth()
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 bg-light-secondary menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <SVG
              src={toAbsoluteUrl('/media/icons/duotune/communication/com006.svg')}
              width={50}
              height={50}
              title='iconoPerfil'
            />
          </div>

          {loading.datosCliente ? (
            <div data-kt-indicator='on'>
              <span className='indicator-progress'>
                <span className='spinner-border spinner-border-sm align-middle m-5'></span>
              </span>
            </div>
          ) : (
            <div className='d-flex flex-column'>
              <div className='fw-bolder d-flex align-items-center fs-5'>
                {datosCliente?.primerNombre} {datosCliente?.primerApellido}
              </div>
              <a  className='fw-bold text-gray-700 text-hover-primary fs-7'>
                {datosCliente?.correo}
              </a>
            </div>
          )}
        </div>
      </div>

      <div className='separator my-2'></div>
      {/* 
      {!location.pathname.includes('/dashboard') && (
        <div className='menu-item px-5'>
          <Link to={'/dashboard'} className='menu-link px-5'>
            Pagos
          </Link>
        </div>
      )}
      {!location.pathname.includes('/perfil') && (
        <div className='menu-item px-5'>
          <Link to={'/perfil'} className='menu-link px-5'>
            Perfil
          </Link>
        </div>
      )} */}

      <div
        className='menu-item mx-2 px-5 py-3 text-hover-danger bg-hover-light-danger'
        data-bs-toggle='modal'
        data-bs-target='#logout'
      >
        {/* dialog en ClienteHeader con el id='logout'*/}
        Cerrar sesión
      </div>
    </div>
  )
}

export {HeaderUserMenu}
