import { ActividadEconomicaModelo } from '../interfaces/ActividadEconomica'
import {UbicacionModelo} from '../interfaces/UbicacionModelo'
import { axios } from './axiosInstance'

export class InformacionServicios {
  public static ObtenerUbicaciones = (): Promise<UbicacionModelo[]> => {
    return new Promise(async (res, rej) => {
      try {
        const ubicacion = await axios.get<UbicacionModelo[]>('/ubicacion')
        res(ubicacion)
      } catch (err) {
        rej(err)
      }
    })
  }

  public static ObtenerActividadesEconmica = (): Promise<ActividadEconomicaModelo[]> => {
    return new Promise(async (res, rej) => {
      try {
        const actividadesEconmicas = await axios.get<ActividadEconomicaModelo[]>(
          '/actividad-economica'
        )
        res(actividadesEconmicas)
      } catch (err) {
        rej(err)
      }
    })
  }
}
