import { FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import { MiPerfil } from '../pages/cliente/MiPerfil'
import { PageTitle } from '../../_metronic/layout/core'
import { TerceroActualizarPagina } from '../pages/cliente/ClienteIndex'
import { TerceroInformacionPagina } from '../pages/pagos/Pagos'
import { PlanPagosPagina } from '../pages/planPagos/PlanPagosPagina'

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/pagos' />} />
        {/* Pages */}
        <Route
          path='perfil'
          element={
            <>
              <PageTitle>Perfil</PageTitle>
              <MiPerfil />
              <TerceroActualizarPagina />
            </>
          }
        />
        <Route
          path='pagos'
          element={
            <>
              <PageTitle>Inicio</PageTitle>
              <TerceroInformacionPagina />
            </>
          }
        />
        <Route
          path='plan-pagos'
          element={
            <>
              <PageTitle>Plan de pagos</PageTitle>
              <PlanPagosPagina />
            </>
          }
        />

        <Route index element={<Navigate to='/pagos' />} />

        {/* Lazy Modules */}

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
