import {FC, ReactNode} from 'react'

interface FormularioLayouProps {
  nombre: string
  children: ReactNode
}

export const FormularioCampo: FC<FormularioLayouProps> = ({nombre, children}) => {
  return (
    <>
      <div className='col-4'>
        <label className='col-lg-4 col-form-label fw-bold fs-6'>{nombre}</label>
      </div>
      <div className='col-8'>{children}</div>
    </>
  )
}
