import {
  PinModel,
  SesionClienteModel,
  DatosClienteModel,
  ValuesActualizarModel,
} from '../interfaces/TerceroModelo'
import { PropiedadTercero } from '../interfaces/propiedades/PropiedadesTercero'
import {axios} from './axiosInstance'

export class TercerosServicios {
  public static generarPinWhatsapp = (identificacion: string): Promise<PinModel | null> => {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<PinModel>(`/sesion/generar-pin/whatsapp`, {
          identificacion,
        })
        res(response)
      } catch (err) {
        res(null)
      }
    })
  }
  public static generarPinCorreo = (identificacion: string): Promise<PinModel | null> => {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<PinModel>(`/sesion/generar-pin/correo`, {identificacion})
        res(response)
      } catch (err) {
        res(null)
      }
    })
  }
  public static Logout = () => {
    return new Promise(async (res, rej) => {
      try {
        await axios.post(`/cerrar-sesion`)
        res(true)
      } catch (err) {
        res(null)
      }
    })
  }
  public static sesionCliente = (
    identificacion: string,
    pin: string
  ): Promise<SesionClienteModel | null> => {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.post<SesionClienteModel>(`/sesion-cliente`, {
          identificacion,
          pin,
        })
        res(response)
      } catch (err) {
        res(null)
      }
    })
  }
  public static ObtenerUsuario = (): Promise<DatosClienteModel | null> => {
    return new Promise(async (res, rej) => {
      try {
        const terceros = await axios.get<DatosClienteModel>(`/tercero/usuario`)
        res(terceros)
      } catch (err) {
        res(null)
      }
    })
  }
  public static ObtenerPorID = (idTercero: string): Promise<DatosClienteModel | null> => {
    return new Promise(async (res, rej) => {
      try {
        const terceros = await axios.get<DatosClienteModel>(`/tercero?Id=${idTercero}`)
        res(terceros)
      } catch (err) {
        res(null)
      }
    })
  }
  public static Actualizar = (
    values: ValuesActualizarModel
  ): Promise<ValuesActualizarModel | null> => {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.put<ValuesActualizarModel>('/tercero/cliente', {...values})
        res(response)
      } catch (err) {
        res(null)
      }
    })
  }
  public static ObtenerPropiedades = (idTercero: string) : Promise<PropiedadTercero[]> => {
    return new Promise(async(res, rej)=>{
        try{
            const propiedad = await axios.get<PropiedadTercero[]>(`/propiedad-tercero-id?TerceroId=${idTercero}`);
            
            propiedad.forEach((c, i, a) => {
                var fechaInicial = new Date(Date.parse(c.fechaLimitePagoInicial));
                var fechaIniciaFinanciacion = new Date(Date.parse(c.fechaInicioFinanciacion));
                a[i].fechaLimitePagoInicial = fechaInicial.formato();
                a[i].fechaInicioFinanciacion = fechaIniciaFinanciacion.formato();
            });
            res(propiedad);
        }catch(err){
            res([]);
        }
    });
}
}
