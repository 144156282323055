import { Suspense, useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { AuthInit, getAuth, useAuth } from './modules/auth'
import { ThemeModeProvider } from '../_metronic/partials'
import { TerceroProvider } from './shared/providers/TerceroProvider'
import * as alertify from "alertifyjs";

const App = () => {
  
  const {logout} = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(()=>{
    if(location.pathname!=="/auth" && location.pathname!=="/"){
      const newAuth = getAuth();
      if(newAuth===undefined){
        alertify.alert("Sesion vencida", "Se ha vencido la sesión del usuario, por favor inicia sesión nuevamente.", ()=>{
          logout(); 
          if(!window.location.href.includes("auth")){
            setTimeout(()=>navigate("/auth"), 2000)
          }
        });
      }
    }
  }, [location.pathname])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <ThemeModeProvider>
            <AuthInit>
              <TerceroProvider>
                <Outlet />
                <MasterInit />
              </TerceroProvider>
            </AuthInit>
          </ThemeModeProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export { App }
