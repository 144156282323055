import {FormikProps, useFormik} from 'formik'
import {createContext, FC, useState, useContext, useEffect} from 'react'
import {
  DatosClienteModel,
  datosClienteSchema,
} from '../../../../interfaces/TerceroModelo'
import {useAuth} from '../../../modules/auth'
import {WithChildren} from '../../../../_metronic/helpers'
import {TercerosServicios} from '../../../../servicios/tercerosServicios'
import clsx from 'clsx'
import { datosTerceroIniciales } from '../../../../interfaces/informacion'

interface FormularioProviderProps {
  formik: FormikProps<DatosClienteModel>
}

const initialFormularioProvider: FormularioProviderProps = {
  // @ts-ignore
  formik: {},
}

const FormularioProviderContext = createContext<FormularioProviderProps>(initialFormularioProvider)

const FormularioProvider: FC<WithChildren> = ({children}) => {
  const [response, setResponse] = useState<boolean>(false)
  const {datosCliente, loading, setLoading} = useAuth()

  const formik = useFormik({
    initialValues: datosTerceroIniciales,
    validationSchema: datosClienteSchema,
    enableReinitialize: true,
    onSubmit: async (values, {setStatus, setSubmitting, setErrors}) => {
      setStatus(undefined)
      setLoading({...loading, ...{SavingDatosCliente: 2}})
      const res = await TercerosServicios.Actualizar({...values})
      if (res) {
        setSubmitting(false)
        setStatus({text: 'La información se guardó con éxito!', bg: 'success'})
      } else {
        setStatus({text: 'Información no guardada', bg: 'danger'})
      }
      setResponse(!response)
    },
  })

  useEffect(() => {
    if (loading.SavingDatosCliente) {
      delete loading.SavingDatosCliente
      setLoading({...loading})
    }
    // eslint-disable-next-line
  }, [response])
  useEffect(() => {
    if (datosCliente) {
      formik.setValues({...datosCliente})
    }
    // eslint-disable-next-line
  }, [datosCliente])

  return (
    <FormularioProviderContext.Provider
      value={{
        formik
      }}
    >
      <form className='form' onSubmit={formik.handleSubmit} noValidate>
        {children}
        {formik.status && (
          <div
            className={clsx(
              'mb-lg-15 alert',
              'bg-light-' + formik.status.bg,
              'alert-' + formik.status.bg
            )}
          >
            <div className='alert-text font-weight-bold'>{formik.status.text}</div>
          </div>
        )}
      </form>
    </FormularioProviderContext.Provider>
  )
}

const useFormulario = () => useContext(FormularioProviderContext)

export {FormularioProvider, useFormulario}
